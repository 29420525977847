import classes from './style.module.scss';
import classNames from 'classnames';
import ErrorBoundary from 'common/components/ErrorBoundary';
import EvaluatedChassis from './EvaluatedChassis';
import FleetSummary from './FleetSummary';
import GetEnhancedDepot from 'common/components/GetEnhancedDepot';
import HelmetTitle from 'common/components/HelmetTitle';
import HistoricalStops from './HistoricalStops';
import Menu from './utils/Menu';
import PageHeaderHome from './utils/PageHeaderHome';
import PageNotFound from 'common/components/PageNotFound';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useSolutionPerformanceSteps from 'features/presentation/PerformanceSteps/Steps/utils/useSolutionPerformanceSteps';
import useTabs from './utils/useTabs';
import { invariant, Loading } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { useGetEnhancedDepotsHandler } from '@optimization/sa-common';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  useGetDepotsQuery,
  useGetVehiclesQuery,
  useGetSolutionQuery,
  useGetFactBasicDataQuery,
} from 'app/services/solution';

const SolutionPresentationHome = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const solutionQuery = useGetSolutionQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);
  const vehiclesInSolutionQuery = useGetVehiclesQuery(solutionId);
  const factBasicDataQuery = useGetFactBasicDataQuery();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useTabs();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
  });

  const getEnhancedDepotsHandler = useGetEnhancedDepotsHandler();

  const performanceSteps = useSolutionPerformanceSteps({
    factBasicDataResponse: factBasicDataQuery.data,
    vehiclesInSolution: candidatesHandler.filteredCandidates,
    depotsInSolution: depotsQuery.data,
    getEnhancedDepotsHandler,
  });

  const isLoading =
    solutionQuery.isFetching ||
    factBasicDataQuery.isFetching ||
    getEnhancedDepotsHandler.isLoading ||
    candidatesHandler.isLoading ||
    depotsQuery.isLoading ||
    vehiclesInSolutionQuery.isLoading;

  const isError =
    solutionQuery.isError ||
    factBasicDataQuery.isError ||
    getEnhancedDepotsHandler.isError ||
    candidatesHandler.isError ||
    depotsQuery.isError ||
    vehiclesInSolutionQuery.isError;

  const isSuccess =
    solutionQuery.isSuccess &&
    factBasicDataQuery.isSuccess &&
    candidatesHandler.isSuccess &&
    depotsQuery.isSuccess &&
    vehiclesInSolutionQuery.isSuccess &&
    getEnhancedDepotsHandler.isSuccess;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates:
      candidatesHandler.isLoading || vehiclesInSolutionQuery.isLoading || factBasicDataQuery.isFetching,
    isLoadingDepots: depotsQuery.isLoading || getEnhancedDepotsHandler.isLoading,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Presentation home']} />
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <PageHeaderHome
        tabs={tabs.length > 0 ? tabs : undefined}
        tabIndex={tabIndex}
        solutionNamePresentation={solutionQuery.data?.transformed.solutionNamePresentation}
        setTabIndex={setTabIndex}
      >
        {isSuccess && (
          <Menu
            solutionId={solutionId}
            getEnhancedDepotsHandler={getEnhancedDepotsHandler}
            candidatesHandler={candidatesHandler}
          />
        )}
      </PageHeaderHome>
      <div className={classNames(classes['solution-presentation-home'], 'tds-container', 'mt-spacing-24')}>
        {isSuccess && (
          <div className="mt-spacing-48">
            {tabs[tabIndex].name === 'Fleet summary' && (
              <ErrorBoundary>
                <FleetSummary
                  filteredCandidates={candidatesHandler.filteredCandidates}
                  depots={depotsQuery.data}
                  performanceSteps={performanceSteps}
                />
              </ErrorBoundary>
            )}
            {tabs[tabIndex].name === 'Historical stops' && (
              <ErrorBoundary>
                <HistoricalStops
                  solutionId={solutionId}
                  solution={solutionQuery.data}
                  filteredCandidates={candidatesHandler.filteredCandidates}
                />
              </ErrorBoundary>
            )}
            {tabs[tabIndex].name === 'Evaluated chassis' && (
              <ErrorBoundary>
                <EvaluatedChassis solutionId={solutionId} filteredCandidates={candidatesHandler.filteredCandidates} />
              </ErrorBoundary>
            )}
          </div>
        )}
        {depotsQuery.data &&
          depotsQuery.data.map((depot) => (
            <GetEnhancedDepot
              key={depot.DepotId}
              solutionId={solutionId}
              depotId={depot.DepotId}
              vehiclesInSolution={vehiclesInSolutionQuery.data}
              setEnhancedDepot={getEnhancedDepotsHandler.setEnhancedDepot}
            />
          ))}
      </div>
    </>
  );
};

export default SolutionPresentationHome;
