import useBatterySystems from './useBatterySystems';
import useBodyLengths from './useBodyLengths';
import useBusWheelConfigurations from './useBusWheelConfigurations';
import useChargingTypesWithMaxPower from './useChargingTypesWithMaxPower';
import useEngineGearboxes from './useEngineGearboxes';
import useFloorHeights from './useFloorHeights';
import { ChargingSpecification, FactBasicBus } from '@optimization/sa-common';
import { useMemo } from 'react';

export interface BusConfiguration {
  wheelConfigurations: string[];
  engineGearboxes: string[];
  batterySystems: number[];
  chargingTypesWithMaxPower: ChargingSpecification[];
  bodyLengths: string[];
  floorHeights: string[];
}

const useBusConfiguration = (basicBusses: FactBasicBus[]): BusConfiguration => {
  const wheelConfigurations = useBusWheelConfigurations({
    basicVehicles: basicBusses,
  });

  const engineGearboxes = useEngineGearboxes({
    basicVehicles: basicBusses,
  });

  const batterySystems = useBatterySystems(basicBusses);

  const chargingTypesWithMaxPower = useChargingTypesWithMaxPower({
    basicVehicles: basicBusses,
  });

  const bodyLengths = useBodyLengths({
    basicBusses,
  });

  const floorHeights = useFloorHeights({
    basicBusses,
  });

  return useMemo(() => {
    return {
      wheelConfigurations,
      engineGearboxes,
      batterySystems,
      chargingTypesWithMaxPower,
      bodyLengths,
      floorHeights,
    };
  }, [batterySystems, chargingTypesWithMaxPower, engineGearboxes, wheelConfigurations, bodyLengths, floorHeights]);
};

export default useBusConfiguration;
