import PresentationList from '../utils/PresentationList';
import PresentationListItem from '../utils/PresentationList/PresentationListItem';
import { ExecutedVehicleProductPto, PtoDevices } from '@optimization/sa-common';
import { roundTo } from '@optimization/ssi-common';

interface Props {
  netEc: number;
  ptoDevices: PtoDevices;
  executedVehicleProductPTOs: ExecutedVehicleProductPto[];
  chassisType?: string;
}

const EnergyConsumption = ({ netEc, ptoDevices, executedVehicleProductPTOs, chassisType }: Props) => (
  <>
    <PresentationList>
      <PresentationListItem label="NET EC" value={`${roundTo(netEc, 2).toFixed(2)} kWh/km`} />
      {chassisType === 'Truck' && (
        <>
          <PresentationListItem label="PTO" value="" className="tds-u-mt2" />
          {executedVehicleProductPTOs.length > 0 ? (
            <>
              {executedVehicleProductPTOs.map((pto) => (
                <PresentationListItem
                  key={pto.Type}
                  label={ptoDevices[pto.Type]?.Name}
                  labelClassName="tds-text-grey-700"
                  value={`${roundTo(pto.ElectricalConsumption, 4).toString()} kWh/km`}
                />
              ))}
            </>
          ) : (
            <PresentationListItem label="Not configured" labelClassName="tds-text-grey-700" value="" />
          )}
        </>
      )}
    </PresentationList>
  </>
);

export default EnergyConsumption;
