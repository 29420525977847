import { sortNumbers } from '@optimization/ssi-common';
import { useMemo } from 'react';
import {
  EnhancedDepotsHandler,
  DepotEnhanced,
  ListDepotTransformed,
  ListVehicleEnhanced,
} from '@optimization/sa-common';
import {
  SolutionPerformanceStepDepot,
  SolutionPerformanceStepVehicle,
  VehiclesCountByChassisAdaptation,
} from './types';

interface Props {
  vehiclesInSolution?: ListVehicleEnhanced[];
  depotsInSolution?: ListDepotTransformed[];
  getEnhancedDepotsHandler: EnhancedDepotsHandler;
}

const useComplements = ({ vehiclesInSolution, depotsInSolution, getEnhancedDepotsHandler }: Props) =>
  useMemo(() => {
    let years: number[] = [];
    let vehicles: { [key: string]: SolutionPerformanceStepVehicle[] } = {};
    let depots: { [key: string]: SolutionPerformanceStepDepot[] } = {};

    let vehiclesCountByChassisTypeAndAdaptation: {
      [year: string]: VehiclesCountByChassisAdaptation;
    } = {};

    if (vehiclesInSolution && vehiclesInSolution?.length > 0 && depotsInSolution) {
      const currentYear = new Date().getFullYear();

      const uniqueYears = Array.from(
        new Set(vehiclesInSolution.map((item) => Number(item.PerformanceStep)).sort(sortNumbers)),
      );

      const startIndex = uniqueYears.findIndex((year) => year >= currentYear);
      const endIndex = Math.min(startIndex + 10, uniqueYears.length);

      const filteredYears = startIndex >= 0 ? uniqueYears.slice(startIndex, endIndex) : [currentYear];

      for (let i = 0; i <= filteredYears.length; i++) {
        const loopYear = filteredYears[i];

        for (const vehicle of vehiclesInSolution) {
          const vehicleYear = Number(vehicle.PerformanceStep);

          if (vehicleYear <= loopYear) {
            const chassisAdaptation =
              vehicle.ChassisType === 'Truck'
                ? `${vehicle.ChassisType || ''}-${vehicle.ChassisAdaptation || ''}`
                : 'Bus';

            if (!years.includes(loopYear)) {
              years.push(loopYear);
            }

            if (!vehicles[loopYear]) {
              vehicles[loopYear] = [];
            }

            if (vehicles[loopYear].find((existingVehicle) => existingVehicle.Id === vehicle.Id)) {
              continue;
            }

            if (!vehiclesCountByChassisTypeAndAdaptation?.[loopYear]) {
              vehiclesCountByChassisTypeAndAdaptation[loopYear] = {};
            }

            const vehicleHasWantedYear =
              vehicleYear === loopYear || (vehicleYear < filteredYears[0] && filteredYears[0] === loopYear);

            if (vehicleHasWantedYear) {
              if (!vehiclesCountByChassisTypeAndAdaptation?.[loopYear]?.[chassisAdaptation]) {
                vehiclesCountByChassisTypeAndAdaptation[loopYear][chassisAdaptation] = 0;
              }

              vehiclesCountByChassisTypeAndAdaptation[loopYear][chassisAdaptation]++;
            }

            const depot = depotsInSolution.find((depotInSolution) =>
              depotInSolution.MappedVehicles?.includes(vehicle.Id),
            );

            let enhancedDepot: DepotEnhanced | undefined;

            if (depot) {
              enhancedDepot = getEnhancedDepotsHandler.keyedEnhancedDepotQueries?.[depot.DepotId]?.data;

              if (enhancedDepot) {
                if (!depots[loopYear]) {
                  depots[loopYear] = [];
                }

                const depotByYear = depots[loopYear].find(
                  (alreadyAddedDepot) => alreadyAddedDepot.Id === depot.DepotId,
                );

                if (depotByYear) {
                  depotByYear.vehicles.push(vehicle);
                } else {
                  depots[loopYear].push({
                    ...enhancedDepot,
                    peakChargingPowerDemand: 0,
                    vehicles: [vehicle],
                    chargers: [],
                    groupedChargers: [],
                    chargersCount: enhancedDepot.InfrastructureProducts.filter((infrastructureProduct) =>
                      infrastructureProduct.RequiredAt ? parseInt(infrastructureProduct.RequiredAt) <= loopYear : false,
                    ).length,
                  });
                }
              }
            }

            if (vehicleHasWantedYear) {
              vehicles[loopYear].push({
                ...vehicle,
                depot: enhancedDepot,
              });
            }
          }
        }
      }
    }

    years.sort();

    return {
      years,
      vehicles,
      depots,
      vehiclesCountByChassisTypeAndAdaptation,
    };
  }, [vehiclesInSolution, depotsInSolution, getEnhancedDepotsHandler]);

export default useComplements;
