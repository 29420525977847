import InfoList, { InfoListItem } from 'common/components/InfoList';
import { SolutionPerformanceStepDepot } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  depot: SolutionPerformanceStepDepot;
  viewMode: 'teaser' | 'detail';
  className?: string;
}

const DepotInfoList = ({ depot, viewMode, className }: Props) => {
  const showTeaserChargers = useMemo(() => Boolean(viewMode === 'teaser'), [viewMode]);

  const showDetailChargers = useMemo(
    () => Boolean(viewMode === 'detail' && depot.groupedChargers.length),
    [viewMode, depot],
  );

  const items = useMemo(() => {
    const items: InfoListItem[] = [];

    if (depot?.MappedVehicles) {
      items.push({
        label: 'Mapped vehicles',
        value: depot.vehicles.length,
      });
    }

    items.push({
      label: 'Peak power demand',
      value: `${depot.peakChargingPowerDemand} kW`,
    });

    if (showTeaserChargers) {
      items.push({
        label: 'Chargers',
        value: depot.chargers.length,
      });
    } else if (showDetailChargers) {
      items.push({
        label: 'Chargers',
        value: depot.groupedChargers
          .map((charger) => `${charger.count}x ${charger.brand} ${charger.power} kW ${charger.satellites} satellites\n`)
          .join(''),
      });
    }

    return items;
  }, [depot, showTeaserChargers, showDetailChargers]);

  return <InfoList longValue={showDetailChargers} items={items} className={className} />;
};

export default DepotInfoList;
