import classes from './style.module.scss';
import HistoricalStops, { StopStatistics } from 'features/candidate/HistoricalStops';
import useHoursAndMinutes from './useHoursAndMinutes';
import { ListVehicleEnhanced, MapLocationV2VM } from '@optimization/sa-common';
import { Spinner } from '@optimization/ssi-common';
import { useMemo, useState } from 'react';

interface Props {
  solutionId: string;
  enhancedListVehicles: ListVehicleEnhanced[];
  mapLocation?: MapLocationV2VM;
}

const SolutionStops = ({ solutionId, enhancedListVehicles, mapLocation }: Props) => {
  const [stopStatistics, setStopStatistics] = useState<StopStatistics | undefined>();
  const [stopsIsLoading, setStopsIsLoading] = useState(false);

  const medianStopDurationMin = useHoursAndMinutes(stopStatistics?.medianStopDuration?.min);
  const medianStopDurationMax = useHoursAndMinutes(stopStatistics?.medianStopDuration?.max);

  const startDate = useMemo(() => (mapLocation ? new Date(mapLocation.StartDate) : undefined), [mapLocation]);
  const endDate = useMemo(() => (mapLocation ? new Date(mapLocation.EndDate) : undefined), [mapLocation]);

  return (
    <div className={classes['solution-stops']}>
      <div className={classes.map}>
        <HistoricalStops
          solutionId={solutionId}
          enhancedVehicles={enhancedListVehicles}
          mapLocation={mapLocation}
          setStopStatistics={setStopStatistics}
          setStopsIsLoading={setStopsIsLoading}
          mapHeight="65vh"
        />
      </div>
      <ul className={classes.info}>
        <li>
          <h1 className="tds-headline-04">CHASSIS</h1>
          <div className="tds-paragraph-02">{enhancedListVehicles.length}</div>
        </li>
        <li>
          <h1 className="tds-headline-04">STOPS</h1>
          <div className="tds-paragraph-02">
            {stopsIsLoading ? <Spinner size="xs" /> : stopStatistics?.numberOfStops.toLocaleString()}
          </div>
        </li>
        <li>
          <h1 className="tds-headline-04">STOP DURATION</h1>
          <div className="tds-paragraph-02">
            {stopsIsLoading ? (
              <Spinner size="xs" />
            ) : (
              <>
                {medianStopDurationMin?.hours ? `${medianStopDurationMin.hours}h` : ''}
                {medianStopDurationMin?.minutes ? ` ${medianStopDurationMin.minutes}min` : ''} –
                {medianStopDurationMax?.hours ? ` ${medianStopDurationMax.hours}h` : ''}
                {medianStopDurationMax?.minutes ? ` ${medianStopDurationMax.minutes}m` : ''}
              </>
            )}
          </div>
        </li>
        {startDate && endDate && (
          <li>
            <h1 className="tds-headline-04">TIME WINDOW</h1>
            <div className="tds-paragraph-02">
              {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SolutionStops;
