import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  performanceSteps: SolutionPerformanceStep[];
  yearParam: string;
}

const useSelectedPerformanceStep = ({ performanceSteps, yearParam }: Props) =>
  useMemo(
    () => performanceSteps.find((performanceStep) => performanceStep.year.toString() === yearParam),
    [performanceSteps, yearParam],
  );

export default useSelectedPerformanceStep;
