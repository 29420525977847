import classes from './style.module.scss';
import classNames from 'classnames';

export type InfoListItem = {
  label: string;
  value: string | number;
};

interface Props {
  items: InfoListItem[];
  className?: string;
  longValue?: boolean;
}

const InfoList = ({ items, className, longValue }: Props) => (
  <ul className={classNames(classes['info-list'], className)}>
    {items.map((item, index) => (
      <li key={index}>
        <span className={classes.label}>{item.label}:</span>
        <span
          className={classNames(classes.value, {
            [classes.large]: longValue,
          })}
        >
          {item.value}
        </span>
      </li>
    ))}
  </ul>
);

export default InfoList;
