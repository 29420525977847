import * as d3 from 'd3';
import { AxisOptions, UserSerie } from 'react-charts';
import { DataPoint } from '@optimization/sa-common';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import { roundTo } from '@optimization/ssi-common';
import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

const useElectrificationJourneyData = (
  performanceSteps: SolutionPerformanceStep[],
  filteredCandidates: ListVehicleEnhanced[],
) => {
  const hasMultiplePerformanceSteps = useMemo(
    () =>
      Object.keys(
        filteredCandidates
          .filter((vehicle) => vehicle.PerformanceStep)
          .reduce((acc, vehicle) => ({ ...acc, [vehicle.PerformanceStep || '']: vehicle.PerformanceStep }), {}),
      ).length > 1,
    [filteredCandidates],
  );

  const yearsAndVehiclesCount = useMemo(() => {
    let result = [];

    if (performanceSteps.length === 1) {
      result.push({
        year: performanceSteps[0].year - 1,
        vehiclesCount: hasMultiplePerformanceSteps ? performanceSteps[0].vehicles.length : 0,
      });
    }

    const firstYear = performanceSteps[0].year;
    const lastYear = performanceSteps[performanceSteps.length - 1].year;

    for (let year = firstYear; year <= lastYear; year++) {
      const vehiclesCount = performanceSteps
        .filter((performanceStep) => performanceStep.year <= year)
        .reduce((acc, performanceStep) => acc + performanceStep.vehicles.length, 0);

      result.push({ year, vehiclesCount });
    }

    return result;
  }, [performanceSteps, hasMultiplePerformanceSteps]);

  const vehiclesCount = useMemo(
    () => performanceSteps.reduce((acc, performanceStep) => acc + performanceStep.vehicles.length, 0),
    [performanceSteps],
  );

  const primaryAxis = useMemo<AxisOptions<DataPoint>>(
    () => ({
      getValue: (datum) => datum.primary,
      tickCount: yearsAndVehiclesCount.length - 1,
      formatters: {
        scale: (value: string) => value,
      },
    }),
    [yearsAndVehiclesCount.length],
  );

  const secondaryAxes = useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        min: 0,
        elementType: 'area',
        curve: (context) => d3.curveLinear(context),
        getValue: (datum) => datum.secondary,
        formatters: {
          scale: (value: string) => `${value}%`,
        },
      },
    ],
    [],
  );

  const data: UserSerie<DataPoint>[] = useMemo(
    () => [
      {
        label: 'Fleet electrified',
        data: yearsAndVehiclesCount.map((yearAndVehiclesCount) => ({
          primary: yearAndVehiclesCount.year,
          secondary: roundTo((yearAndVehiclesCount.vehiclesCount / vehiclesCount) * 100, 0),
        })),
      },
    ],
    [yearsAndVehiclesCount, vehiclesCount],
  );

  return {
    primaryAxis,
    secondaryAxes,
    data,
  };
};

export default useElectrificationJourneyData;
