import { useMemo } from 'react';

const useHoursAndMinutes = (seconds?: number) =>
  useMemo(() => {
    if (typeof seconds === 'number') {
      return {
        hours: parseInt(Number(seconds / (60 * 60)).toString()),
        minutes: parseInt(Number((seconds % (60 * 60)) / 60).toString()),
      };
    }
  }, [seconds]);

export default useHoursAndMinutes;
