import { createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface ElementController {
  sideMenuRef: React.RefObject<HTMLTdsSideMenuElement>;
  enableSidebar: boolean;
  setEnableSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMobileNav: () => void;
}

const ElementControllerContext = createContext<ElementController | null>(null);

export const ElementControllerProvider = ({ children }: { children: ReactNode }) => {
  const [enableSidebar, setEnableSidebar] = useState(true);
  const sideMenuRef = useRef<HTMLTdsSideMenuElement>(null);

  const toggleMobileNav = useCallback(() => {
    if (sideMenuRef.current) {
      sideMenuRef.current.open = !sideMenuRef.current.open;
    }
  }, [sideMenuRef]);

  const value = useMemo(
    () => ({
      sideMenuRef,
      enableSidebar,
      setEnableSidebar,
      toggleMobileNav,
    }),
    [sideMenuRef, enableSidebar, setEnableSidebar, toggleMobileNav],
  );

  return <ElementControllerContext.Provider value={value}>{children}</ElementControllerContext.Provider>;
};

export const useElementController = () => useContext(ElementControllerContext);
