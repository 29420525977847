import useAxleDistances from './useAxleDistances';
import useBatterySystems from './useBatterySystems';
import useCabs from './useCabs';
import useChargingTypesWithMaxPower from './useChargingTypesWithMaxPower';
import useEngineGearboxes from './useEngineGearboxes';
import useMaxGtws from './useMaxGtws';
import useTruckWheelConfigurations from './useTruckWheelConfigurations';
import { ChargingSpecification, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

export interface TruckConfiguration {
  wheelConfigurations: string[];
  cabs: string[];
  engineGearboxes: string[];
  maxGtws: number[];
  batterySystems: number[];
  chargingTypesWithMaxPower: ChargingSpecification[];
  axleDistanceMinMax: string;
}

const useTruckConfiguration = (basicTrucks: FactBasicTruck[]): TruckConfiguration => {
  const wheelConfigurations = useTruckWheelConfigurations({
    basicVehicles: basicTrucks,
  });

  const axleDistances = useAxleDistances({
    basicTrucks,
  });

  const cabs = useCabs({
    basicTrucks,
  });

  const engineGearboxes = useEngineGearboxes({
    basicVehicles: basicTrucks,
  });

  const maxGtws = useMaxGtws({
    basicTrucks,
  });

  const batterySystems = useBatterySystems(basicTrucks);

  const chargingTypesWithMaxPower = useChargingTypesWithMaxPower({
    basicVehicles: basicTrucks,
  });

  const axleDistanceMinMax = useMemo(() => {
    const axleDistanceMin = Math.min(...axleDistances);
    const axleDistanceMax = Math.max(...axleDistances);
    if (axleDistanceMin === axleDistanceMax) return `${axleDistanceMin} mm`;
    return `${axleDistanceMin} - ${axleDistanceMax} mm`;
  }, [axleDistances]);

  return useMemo(() => {
    return {
      wheelConfigurations,
      cabs,
      engineGearboxes,
      maxGtws,
      batterySystems,
      chargingTypesWithMaxPower,
      axleDistanceMinMax,
    };
  }, [
    axleDistanceMinMax,
    batterySystems,
    cabs,
    chargingTypesWithMaxPower,
    engineGearboxes,
    maxGtws,
    wheelConfigurations,
  ]);
};

export default useTruckConfiguration;
