import DebouncedTimeField from '../utils/DebouncedTimeField';

interface Props {
  departureTime: string;
  slowChargeStartTime: string;
}

const DepartureAndSlowChargeFields = ({ departureTime, slowChargeStartTime }: Props) => (
  <>
    <DebouncedTimeField
      label="Departure time"
      onChange={() => null}
      disabled
      value={departureTime}
      requiredText="Departure time is required"
    />
    <DebouncedTimeField
      label="Slow-charge start time"
      onChange={() => null}
      disabled
      value={slowChargeStartTime}
      requiredText="Slow-charge start time is required"
    />
  </>
);

export default DepartureAndSlowChargeFields;
