import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

const useBatterySystems = (basisVehicles?: (FactBasicTruck | FactBasicBus)[]) =>
  useMemo(
    () =>
      basisVehicles ? [...new Set(basisVehicles?.map((basisVehicle) => basisVehicle.InstalledEnergyKWh))].sort() : [],
    [basisVehicles],
  );

export default useBatterySystems;
