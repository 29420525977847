import { useMemo } from 'react';
import { cellToLatLng } from 'h3-js';

// Exclude incorrect coords
// Todo: Solve in backend instead
const incorrectCoords = [{ lat: 79.24239850975904, lng: 38.02340700796988 }];

interface Props {
  tripAggregates?: {
    ChassisList: string[];
    DestinationH3CellIndex: string;
    OriginH3CellIndex: string;
    TripsCount: number;
  }[];
  run: boolean;
}

const useTrips = ({ run, tripAggregates }: Props) =>
  useMemo(() => {
    let result = [];

    if (run && tripAggregates) {
      for (const trip of tripAggregates) {
        const start = cellToLatLng(trip.OriginH3CellIndex);
        const end = cellToLatLng(trip.DestinationH3CellIndex);

        if (
          incorrectCoords.some(
            (incorrectCoord) =>
              (incorrectCoord.lat === start[0] && incorrectCoord.lng === start[1]) ||
              (incorrectCoord.lat === end[0] && incorrectCoord.lng === end[1]),
          )
        ) {
          continue;
        }

        result.push({ trip, start, end });
      }
    }

    return result;
  }, [run, tripAggregates]);

export default useTrips;
