import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';

type DefineManuallyTabs = 'Fleet summary' | 'Historical stops' | 'Evaluated chassis';

const useTabs = () =>
  useMemo(() => {
    const inlineTabs: TInlineTabs<DefineManuallyTabs> = [];

    inlineTabs.push({
      name: 'Fleet summary',
    });

    inlineTabs.push({
      name: 'Evaluated chassis',
    });

    inlineTabs.push({
      name: 'Historical stops',
    });

    return inlineTabs;
  }, []);

export default useTabs;
