import classNames from 'classnames';
import HelmetTitle from 'common/components/HelmetTitle';
import PageHeaderAlt from 'common/components/PageHeaderAlt';
import PageNotFound from 'common/components/PageNotFound';
import TruckHero from './utils/TruckHero';
import useBusRows from './utils/useBusRows';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useFilteredBusses from './utils/useFilteredBusses';
import useFilteredTrucks from './utils/useFilteredTrucks';
import useLaunchPeriodTabs from './utils/useLaunchPeriodTabs';
import useLoadingText from 'common/hooks/useLoadingText';
import useShowcaseLaunchPeriods from './utils/useShowcaseLaunchPeriods';
import useTruckRows from './utils/useTruckRows';
import { busColumns, BusRowItem } from './utils/busColumns';
import { InlineTabs, invariant, Loading, ResponsiveTable } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { truckColumns, TruckRowItem } from './utils/truckColumns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEnsureArray } from '@optimization/ssi-common';
import { useGetFactBasicDataQuery, useGetSolutionQuery } from 'app/services/solution';
import { useHomePath } from '@optimization/sa-common';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useParams } from 'react-router-dom';

const SolutionPresentationProductShowcase = () => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const homePath = useHomePath();

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const solutionQuery = useGetSolutionQuery(solutionId);
  const candidatesHandler = useCandidatesHandler({
    solutionId,
  });

  const basicTrucks = useEnsureArray(factBasicDataQuery.data?.FactBasicTrucks);
  const basicBusses = useEnsureArray(factBasicDataQuery.data?.FactBasicBusses);

  const yearSwitcherTabsRef = useRef<HTMLDivElement | null>(null);

  const [launchPeriodTabIndex, setLaunchPeriodTabIndex] = useState(0);
  const [statusTabIndex, setStatusTabIndex] = useState(0);

  const showcaseLaunchPeriods = useShowcaseLaunchPeriods(candidatesHandler.filteredCandidates);

  const hasTrucks = useMemo(
    () => showcaseLaunchPeriods.some((showcaseLaunchPeriod) => showcaseLaunchPeriod.chassisType === 'Truck'),
    [showcaseLaunchPeriods],
  );

  const hasBusses = useMemo(
    () => showcaseLaunchPeriods.some((showcaseLaunchPeriod) => showcaseLaunchPeriod.chassisType === 'Bus'),
    [showcaseLaunchPeriods],
  );

  const selectedShowcaseLaunchPeriod = useMemo(
    () => showcaseLaunchPeriods[launchPeriodTabIndex] || '',
    [launchPeriodTabIndex, showcaseLaunchPeriods],
  );

  const chassisType = useMemo(() => selectedShowcaseLaunchPeriod.chassisType, [selectedShowcaseLaunchPeriod]);

  const launchPeriodPresentationAlt = useMemo(
    () => selectedShowcaseLaunchPeriod.launchPeriodPresentationAlt,
    [selectedShowcaseLaunchPeriod],
  );

  const { articulatedTrucks, rigidTrucks, truckStatus } = useFilteredTrucks({
    basicTrucks,
    launchPeriodPresentationAlt,
    statusTabIndex,
    chassisType,
  });

  const { bussesInStatus, busStatus } = useFilteredBusses({
    basicBusses,
    launchPeriodPresentationAlt,
    statusTabIndex,
    chassisType,
  });

  const truckStatusTabs = useMemo(() => truckStatus.map((status) => ({ name: status })), [truckStatus]);
  const busStatusTabs = useMemo(() => busStatus.map((status) => ({ name: status })), [busStatus]);

  const truckRows = useTruckRows({ articulatedTrucks, rigidTrucks, chassisType });
  const busRows = useBusRows({ bussesInStatus, chassisType });

  const hasRows = useMemo(
    () => (truckRows.length > 0 && chassisType === 'Truck') || (busRows.length > 0 && chassisType === 'Bus'),
    [truckRows, busRows, chassisType],
  );

  const onChangeLaunchPeriodTabIndex = useCallback((index: number) => {
    setLaunchPeriodTabIndex(index);
    setStatusTabIndex(0);
  }, []);

  useEffect(() => {
    candidatesHandler.setVehiclesCount(candidatesHandler.filteredCandidates?.length);
  }, [candidatesHandler]);

  const launchPeriodTabs = useLaunchPeriodTabs({ showcaseLaunchPeriods, hasBusses, hasTrucks });

  useMakeElementsSticky({
    yearSwitcherTabsRef: launchPeriodTabs.length > 0 ? yearSwitcherTabsRef : undefined,
  });

  const isLoading = factBasicDataQuery.isFetching || solutionQuery.isFetching || candidatesHandler.isLoading;
  const isError = factBasicDataQuery.isError || solutionQuery.isError || candidatesHandler.isError;
  const isSuccess = factBasicDataQuery.isSuccess && solutionQuery.isSuccess && candidatesHandler.isSuccess;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates: candidatesHandler.isLoading || factBasicDataQuery.isFetching,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Presentation product showcase']} />
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <PageHeaderAlt
        backLinkText="Back to home"
        backLinkUrl={homePath}
        tabs={launchPeriodTabs.length > 0 ? launchPeriodTabs : undefined}
        tabsRef={yearSwitcherTabsRef}
        tabIndex={launchPeriodTabIndex}
        setTabIndex={onChangeLaunchPeriodTabIndex}
      >
        Explore the shift
      </PageHeaderAlt>
      <div className={classNames('tds-container', 'mt-spacing-48')}>
        {isSuccess && (
          <>
            {hasRows ? (
              <>
                {chassisType === 'Truck' && <TruckHero selectedLaunchPeriod={launchPeriodPresentationAlt} />}
                <div className="mt-spacing-48">
                  {chassisType === 'Truck' && (
                    <>
                      <InlineTabs
                        key={truckStatus.join('-')}
                        modeVariant="primary"
                        tabs={truckStatusTabs}
                        tabIndex={statusTabIndex}
                        setTabIndex={setStatusTabIndex}
                      />
                      <ResponsiveTable<TruckRowItem>
                        className="tds-u-mt3"
                        breakpoint="sm"
                        columns={truckColumns}
                        // @ts-ignore
                        rows={truckRows}
                      />
                    </>
                  )}
                  {chassisType === 'Bus' && (
                    <>
                      <InlineTabs
                        key={busStatus.join('-')}
                        modeVariant="primary"
                        tabs={busStatusTabs}
                        tabIndex={statusTabIndex}
                        setTabIndex={setStatusTabIndex}
                      />
                      <ResponsiveTable<BusRowItem>
                        className="tds-u-mt3"
                        breakpoint="sm"
                        columns={busColumns}
                        rows={busRows}
                      />
                    </>
                  )}
                </div>
              </>
            ) : (
              <p>Found no BEV products for showcase in this solution.</p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SolutionPresentationProductShowcase;
