import useLogout from 'features/auth/utils/useLogout';
import { APP_TITLE, MODE, SOLUTION_ENDPOINT } from 'app/config';
import { Header as SaHeader } from '@optimization/sa-common';
import { useAppSelector } from 'app/hooks';
import { useEffect, useRef } from 'react';
import { useElementController } from 'app/context/ElementControllerContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { usernameSelector } from 'features/auth/authSlice';
import { useStickyHandler } from 'app/context/StickyHandlerContext';

const Navbar = () => {
  const username = useAppSelector(usernameSelector);
  const isAuthenticated = useIsAuthenticated();
  const elementController = useElementController();
  const stickyHandler = useStickyHandler();

  const navbarRef = useRef<HTMLDivElement | null>(null);

  const handleLogout = useLogout();

  useEffect(() => {
    if (navbarRef.current && stickyHandler) {
      stickyHandler.setStickyHeader(navbarRef.current);
    }
  }, [navbarRef, stickyHandler]);

  return elementController ? (
    <SaHeader
      username={username}
      isAuthenticated={isAuthenticated}
      appTitle={APP_TITLE}
      mode={MODE}
      navbarRef={navbarRef}
      solutionEndpoint={SOLUTION_ENDPOINT}
      handleLogout={handleLogout}
      toggleMobileNav={elementController.toggleMobileNav}
    />
  ) : null;
};

export default Navbar;
