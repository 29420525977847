import classes from './style.module.scss';
import classNames from 'classnames';
import useTooltip from './utils/useTooltip';
import { AxisOptions } from 'react-charts';
import { useMemo } from 'react';
import {
  CandidateChargingGraph,
  ChartChargingEvent,
  ChargingEventV2VM,
  GetCandidateChargingTime,
  VehicleEnhanced,
  DataPoint,
} from '@optimization/sa-common';

interface Props {
  chartChargingEvents: ChartChargingEvent[];
  distanceIncludingPreview: DataPoint[];
  dailyRange: number;
  departureTime: string;
  vehicle: VehicleEnhanced;
  dailyOdometerMedianKm: number;
  dailyOdometerLongestKm: number;
  medianTick: number;
  medianTickIsOver: boolean;
  getCandidateChargingTime: GetCandidateChargingTime;
  findChargingEvent: (primaryValue: number) => ChargingEventV2VM | undefined;
}

const CandidateGraph = ({
  chartChargingEvents,
  distanceIncludingPreview,
  dailyRange,
  departureTime,
  vehicle,
  dailyOdometerMedianKm,
  dailyOdometerLongestKm,
  medianTick,
  medianTickIsOver,
  getCandidateChargingTime,
  findChargingEvent,
}: Props) => {
  const primaryAxis = useMemo<Partial<AxisOptions<DataPoint>>>(
    () => ({
      getValue: (datum) => datum.primary,
      max: dailyOdometerLongestKm,
      hardMax: dailyOdometerLongestKm,
      position: 'bottom',
      formatters: {
        scale: () => '',
      },
    }),
    [dailyOdometerLongestKm],
  );

  const secondaryAxes = useMemo<AxisOptions<DataPoint>[]>(
    () => [
      {
        // SoC area
        getValue: (datum) => datum.secondary,
        id: '1',
        position: 'left',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        stacked: false,
        elementType: 'area',
        formatters: {
          scale: (value: number) => `${value}%`,
        },
      },
      // Safety margin
      {
        getValue: (datum) => datum.secondary,
        id: '2',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'line',
        stacked: false,
        show: false,
        position: 'left',
      },
      // Charging events
      {
        getValue: (datum) => datum.secondary,
        id: '3',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'bubble',
        show: false,
        position: 'left',
      },
      {
        getValue: (datum) => datum.secondary,
        id: '4',
        max: 100,
        hardMax: 100,
        min: 0,
        hardMin: 0,
        elementType: 'bar',
        show: false,
        position: 'left',
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        label: 'Base demand',
        secondaryAxisId: '1',
        data: distanceIncludingPreview,
      },
      {
        label: 'Safety margin',
        secondaryAxisId: '2',
        data: [
          {
            primary: 0,
            secondary: (30 * 100) / dailyRange,
          },
          {
            primary: dailyOdometerLongestKm,
            secondary: (30 * 100) / dailyRange,
          },
        ],
      },
      {
        label: 'Charging event',
        secondaryAxisId: '3',
        data: chartChargingEvents,
      },
      {
        label: 'Charging event set up',
        secondaryAxisId: '3',
        data: [],
      },
    ],
    [distanceIncludingPreview, dailyRange, dailyOdometerLongestKm, chartChargingEvents],
  );

  const tooltip = useTooltip({
    departureTime,
    findChargingEvent,
    getCandidateChargingTime,
  });

  const options = useMemo(
    () => ({
      tooltip,
    }),
    [tooltip],
  );

  return (
    <div className={classNames('px-spacing-16', 'py-spacing-16', classes['no-current-datum'])}>
      <CandidateChargingGraph
        className={classNames({
          [classes['median-tick-is-over']]: medianTickIsOver,
          [classes['median-tick-not-over']]: !medianTickIsOver,
        })}
        data={data}
        dailyOdometerMedianKm={dailyOdometerMedianKm}
        dailyOdometerLongestKm={dailyOdometerLongestKm}
        primaryAxis={primaryAxis}
        secondaryAxes={secondaryAxes}
        vehicleColor={vehicle.enhanced.vehicleColor}
        isPresentation
        options={options}
        medianTick={medianTick}
        medianTickIsOver={medianTickIsOver}
      />
    </div>
  );
};

export default CandidateGraph;
