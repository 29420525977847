import Card from 'common/components/Card';
import classNames from 'classnames';
import DepotInfoList from 'features/infrastructure/utils/DepotInfoList';
import { Button, ButtonLink, HomeChargingIcon } from '@optimization/ssi-common';
import { SolutionPerformanceStepDepot } from 'features/presentation/PerformanceSteps/Steps/utils/types';

interface Props {
  depot: SolutionPerformanceStepDepot;
  solutionId: string;
  solutionNamePresentation?: string;
  onClick?: () => void;
}

const DepotTeaser = ({ depot, solutionId, solutionNamePresentation, onClick }: Props) => (
  <Card>
    <HomeChargingIcon size="lg" color="gray" />
    <p className={classNames('tds-u-mt3', 'tds-detail-05', 'tds-text-grey-868')}>{solutionNamePresentation}</p>
    <h3 className={classNames('mt-spacing-4', 'mb-spacing-16', 'tds-paragraph-02', 'tds-text-grey-958')}>
      {depot.Name}
    </h3>
    <DepotInfoList depot={depot} viewMode="teaser" />
    {onClick ? (
      <Button className="tds-u-mt3" text="View depot" variant="secondary" size="sm" onClick={onClick} fullbleed />
    ) : (
      <ButtonLink
        className="tds-u-mt3"
        text="Enter Depot"
        url={`/solution/${solutionId}/infrastructure/${depot.Name}`}
        variant="secondary"
        size="sm"
        fullbleed
      />
    )}
  </Card>
);

export default DepotTeaser;
