import { IColumn, RowItemBase } from '@optimization/ssi-common';
import { ReactNode } from 'react';

export interface TruckRowItem extends RowItemBase {
  trailerTractor: ReactNode;
  rigidTruck: ReactNode;
  title: string;
}

export const truckColumns: IColumn<TruckRowItem>[] = [
  {
    columnKey: 'specifications',
    columnTitle: 'Specifications',
    render: (row: TruckRowItem) => row.title,
  },
  {
    columnKey: 'trailerTractor',
    columnTitle: 'Trailer tractor',
    render: (row: TruckRowItem) => row.trailerTractor,
  },
  {
    columnKey: 'rigidTruck',
    columnTitle: 'Rigid truck',
    render: (row: TruckRowItem) => row.rigidTruck,
  },
];
