import AppInfo from 'features/auth/Login/AppInfo';
import classes from './style.module.scss';
import classNames from 'classnames';
import truckSide from 'assets/truck-side.png';
import useLoadingText from 'common/hooks/useLoadingText';
import { Link } from 'react-router-dom';
import { Loading } from '@optimization/ssi-common';
import { useGetSolutionsQuery } from 'app/services/solution';

function SolutionManager() {
  const solutionsQuery = useGetSolutionsQuery();

  const loadingText = useLoadingText({
    isLoadingSolutions: solutionsQuery.isLoading,
  });

  return (
    <div className={classes.wrapper}>
      <Loading isLoading={solutionsQuery.isLoading} isError={solutionsQuery.isError} loadingText={loadingText} />
      <div className={classes.left}>
        <div className={classes.top}>
          <div>
            <h1 className="tds-headline-01">Welcome to your electric Scania fleet</h1>
          </div>
          {solutionsQuery.isSuccess && solutionsQuery.data.length === 0 && (
            <div className="tds-headline-04 tds-u-mt3">Found no solutions</div>
          )}
          {solutionsQuery.data && (
            <>
              <div className="tds-headline-04 tds-u-mt3">Select a solution</div>
              <ul className={classNames('tds-u-mt1', classes.solutions)}>
                {solutionsQuery.data.map((solution) => (
                  <li key={solution.Id}>
                    <Link to={`/solution/${solution.Id}/presentation/home`}>
                      {solution.transformed.solutionNamePresentation}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
        <AppInfo className={classes.bottom} />
      </div>
      <div className={classes.right}>
        <img src={truckSide} alt="Truck from side" />
      </div>
    </div>
  );
}

export default SolutionManager;
