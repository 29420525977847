import { Button } from '@optimization/ssi-common';
import { useCallback, useEffect, useMemo } from 'react';

interface Props {
  contentName: string;
  itemsCount?: number;
  totalCount?: number;
  itemsPerPage?: number;
  setItemsCount: (value: React.SetStateAction<number | undefined>) => void;
}

const LoadMore = ({ contentName, itemsCount, totalCount, itemsPerPage = 12, setItemsCount }: Props) => {
  const showLoadMoreButton = useMemo(
    () => itemsCount !== undefined && totalCount !== undefined && itemsCount < totalCount,
    [itemsCount, totalCount],
  );

  const onLoadMore = useCallback(() => {
    if (totalCount !== undefined && itemsCount !== undefined) {
      const nextCount = itemsCount + itemsPerPage;

      if (nextCount > totalCount) {
        setItemsCount(totalCount);
      } else {
        setItemsCount(nextCount);
      }
    }
  }, [totalCount, itemsCount, itemsPerPage, setItemsCount]);

  useEffect(() => {
    if (totalCount !== undefined) {
      setItemsCount(totalCount < itemsPerPage ? totalCount : itemsPerPage);
    }
  }, [totalCount, itemsPerPage, setItemsCount]);

  return showLoadMoreButton ? (
    <div className="flex mt-spacing-40">
      <Button
        text={`Load more ${contentName.toLowerCase()}`}
        variant="secondary"
        className="tds-u-ml-auto tds-u-mr-auto tds-u-mt3"
        onClick={onLoadMore}
      />
    </div>
  ) : null;
};

export default LoadMore;
