import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedPerformanceStep?: SolutionPerformanceStep;
  vehiclesCount?: number;
}

const useSplicedListVehicles = ({ selectedPerformanceStep, vehiclesCount }: Props) =>
  useMemo(
    () =>
      selectedPerformanceStep
        ? selectedPerformanceStep.vehicles.filter((_, index) => (vehiclesCount ? index < vehiclesCount : false))
        : [],
    [selectedPerformanceStep, vehiclesCount],
  );

export default useSplicedListVehicles;
