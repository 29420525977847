import { ListDepotTransformed } from '@optimization/sa-common';
import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedPerformanceStep?: SolutionPerformanceStep;
  depot?: ListDepotTransformed;
}

const usePerformanceStepDepot = ({ selectedPerformanceStep, depot }: Props) =>
  useMemo(
    () => selectedPerformanceStep?.depots?.find((item) => item.Id === depot?.DepotId),
    [selectedPerformanceStep, depot],
  );

export default usePerformanceStepDepot;
