import classes from './style.module.scss';
import usePersistentCollapsedHandler from './utils/usePersistentCollapsedHandler';
import { invariant } from '@optimization/ssi-common';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { TdsIcon } from '@scania/tegel-react';
import { TdsSideMenu, TdsSideMenuItem } from '@scania/tegel-react';
import { useElementController } from 'app/context/ElementControllerContext';

const SideNavbarSolutionPresentation = ({ children }: { children: React.ReactNode }) => {
  const { solutionId } = useParams();

  invariant(solutionId);

  const location = useLocation();

  const elementController = useElementController();

  const { persistent, collapsed, sideMenuKey } = usePersistentCollapsedHandler();

  return (
    <div className="side-menu-wrapper">
      {elementController?.enableSidebar && (
        <TdsSideMenu
          id="side-menu"
          aria-label="Side menu"
          data-testid="button-presentation-home-menu-item"
          ref={elementController.sideMenuRef}
          persistent={persistent}
          collapsed={collapsed}
          key={sideMenuKey}
        >
          <TdsSideMenuItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'side-menu-item-active' : '')}
              to={{
                pathname: `/solution/${solutionId}/presentation/home`,
                search: location.search,
              }}
            >
              <TdsIcon name="home" size="24" />
              Solution home
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem data-testid="button-presentation-product-showcase-menu-item">
            <NavLink
              className={({ isActive }) => (isActive ? 'side-menu-item-active' : '')}
              to={{
                pathname: `/solution/${solutionId}/presentation/product-showcase`,
                search: location.search,
              }}
            >
              <TdsIcon name="truck" size="24" />
              Product showcase
            </NavLink>
          </TdsSideMenuItem>
          <TdsSideMenuItem data-testid="button-presentation-performance-steps-menu-item">
            <NavLink
              className={({ isActive }) => (isActive ? 'side-menu-item-active' : '')}
              to={{
                pathname: `/solution/${solutionId}/presentation/performance-steps`,
                search: location.search,
              }}
            >
              <TdsIcon name="report" size="24" />
              Performance steps
            </NavLink>
          </TdsSideMenuItem>
        </TdsSideMenu>
      )}
      <div className={classes['container-margin']}>{children}</div>
    </div>
  );
};

export default SideNavbarSolutionPresentation;
