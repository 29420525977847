import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles?: (FactBasicTruck | FactBasicBus)[];
}

const useChargingTypesWithMaxPower = ({ basicVehicles }: Props) =>
  useMemo(
    () =>
      basicVehicles
        ?.map((basicVehicle) => basicVehicle.ChargingSpecifications)
        .flat()
        .filter(
          (chargingSpecification, index, self) =>
            self.findIndex(
              (chargingSpecificationInner) =>
                chargingSpecificationInner.ChargingPowerKW === chargingSpecification.ChargingPowerKW &&
                chargingSpecificationInner.ChargingType === chargingSpecification.ChargingType,
            ) === index,
        ) || [],
    [basicVehicles],
  );

export default useChargingTypesWithMaxPower;
