import classes from './style.module.scss';
import classNames from 'classnames';
import SimpleTeaser from './Teaser/SimpleTeaser';
import SolutionPerformanceStepTeaser from './Teaser';
import { ListVehicleTransformed } from '@optimization/sa-common';
import { SolutionPerformanceStep } from './utils/types';
import { useMemo } from 'react';

interface Props {
  solutionId: string;
  performanceSteps: SolutionPerformanceStep[];
  nextGenVehicles: {
    trailerNextGenVehicles: ListVehicleTransformed[];
    rigidNextGenVehicles: ListVehicleTransformed[];
  };
}

const SolutionPerformanceSteps = ({ solutionId, performanceSteps, nextGenVehicles }: Props) => {
  const isRigidNextGenVehicles = useMemo(() => nextGenVehicles.rigidNextGenVehicles.length > 0, [nextGenVehicles]);

  const isTrailerNextGenVehicles = useMemo(() => nextGenVehicles.trailerNextGenVehicles.length > 0, [nextGenVehicles]);

  const renderNextGenVehicles = useMemo(
    () =>
      (isRigidNextGenVehicles || isTrailerNextGenVehicles) && (
        <li className={classes['next-gen']}>
          <div>
            <div className="tds-body-01 mb-spacing-32">Next generation</div>
            <div className="flex flex-col gap-16">
              {isTrailerNextGenVehicles && (
                <SimpleTeaser headline="Trailer truck" vehicles={nextGenVehicles.trailerNextGenVehicles} />
              )}
              {isRigidNextGenVehicles && (
                <SimpleTeaser headline="Rigid truck" vehicles={nextGenVehicles.rigidNextGenVehicles} />
              )}
            </div>
          </div>
        </li>
      ),
    [isRigidNextGenVehicles, isTrailerNextGenVehicles, nextGenVehicles],
  );

  return performanceSteps.length ? (
    <>
      <ul
        className={classNames(classes['solution-performance-steps'], classes.desktop, 'horizontal-scroll-transform')}
        data-testid="solution-performance-steps"
      >
        {performanceSteps.map((performanceStep, index) => (
          <li key={performanceStep.year}>
            <SolutionPerformanceStepTeaser
              marginTop={(performanceSteps.length - index) * 24}
              solutionId={solutionId}
              performanceStep={performanceStep}
            />
          </li>
        ))}
        {renderNextGenVehicles}
      </ul>
      <ul className={classNames(classes['solution-performance-steps'], classes.mobile)}>
        {performanceSteps.map((performanceStep) => (
          <li key={performanceStep.year}>
            <SolutionPerformanceStepTeaser marginTop={24} solutionId={solutionId} performanceStep={performanceStep} />
          </li>
        ))}
        {renderNextGenVehicles}
      </ul>
    </>
  ) : null;
};

export default SolutionPerformanceSteps;
