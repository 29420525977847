import HelmetTitle from 'common/components/HelmetTitle';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import {
  evaluatedChassisDataGroups,
  useEvaluatedChassisTrucks,
  useEvaluatedChassisChartSeries,
  useEvaluatedChassisItems,
  useEvaluatedChassisHasTrucks,
  useEvaluatedChassisRigidTrucks,
  useEvaluatedChassisTotalCount,
  useEvaluatedChassisTrailerTractors,
  CountBox,
  EvaluatedChassisAdaptationBox,
  EvaluatedChassisChart,
  EvaluatedChassisInfoGrid,
  EvaluatedChassisStatisticsGrid,
  EvaluatedChassisStatisticsTable,
} from '@optimization/sa-common';

const isPresentation = true;

interface Props {
  solutionId: string;
  filteredCandidates?: ListVehicleEnhanced[];
}

const EvaluatedChassis = ({ solutionId, filteredCandidates = [] }: Props) => {
  const evaluatedTrucks = useEvaluatedChassisTrucks(filteredCandidates);
  const hasTrucks = useEvaluatedChassisHasTrucks(evaluatedTrucks);
  const rigidTrucks = useEvaluatedChassisRigidTrucks(evaluatedTrucks);
  const trailerTractors = useEvaluatedChassisTrailerTractors(evaluatedTrucks);

  const totalCount = useEvaluatedChassisTotalCount({
    rigidTrucksCount: rigidTrucks.length,
    trailerTractorsCount: trailerTractors.length,
  });

  const items = useEvaluatedChassisItems({ rigidTrucks, trailerTractors, totalCount, isPresentation });

  const chartSeries = useEvaluatedChassisChartSeries({ evaluatedTrucks });

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Evaluated chassis']} />
      {hasTrucks && (
        <>
          <EvaluatedChassisInfoGrid className="mt-spacing-32">
            <EvaluatedChassisAdaptationBox items={items} totalCount={totalCount} isPresentation={isPresentation} />
            {items.map((item) => (
              <CountBox key={item.header} header={item.header} subHeader="No. of chassis" count={item.count} />
            ))}
          </EvaluatedChassisInfoGrid>
          <EvaluatedChassisChart className="mt-spacing-40" chartSeries={chartSeries} isPresentation={isPresentation} />
          <EvaluatedChassisStatisticsGrid className="mt-spacing-60">
            {evaluatedChassisDataGroups.map((dataGroup) => (
              <EvaluatedChassisStatisticsTable key={dataGroup.name} chartSerie={chartSeries[dataGroup.name]} />
            ))}
          </EvaluatedChassisStatisticsGrid>
        </>
      )}
      {!hasTrucks && <div className="mt-spacing-32">Found no evaluated chassis</div>}
    </>
  );
};

export default EvaluatedChassis;
