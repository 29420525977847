import { Stops } from '@optimization/sa-common';
import { useEffect } from 'react';
import { useGetStopsQuery } from 'app/services/solution';
import { useToast } from '@optimization/ssi-common';

interface Props {
  solutionId: string;
  queryId: string;
  setStopsData: (data: Stops) => void;
  setStopsState: React.Dispatch<
    React.SetStateAction<{
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
    }>
  >;
}

const GetStops = ({ solutionId, queryId, setStopsData, setStopsState }: Props) => {
  const showToast = useToast();

  const getStopsQuery = useGetStopsQuery({
    solutionId,
    queryId,
  });

  useEffect(() => {
    if (getStopsQuery.isSuccess) {
      setStopsData(getStopsQuery.data);

      if (getStopsQuery.data.StopAggregates.length === 0) {
        showToast({
          header: 'No stops found within the specified filter range',
          variant: 'warning',
        });
      }
    }
  }, [getStopsQuery, queryId, setStopsData, showToast]);

  useEffect(() => {
    setStopsState({
      isError: getStopsQuery.isError,
      isLoading: getStopsQuery.isLoading,
      isSuccess: getStopsQuery.isSuccess,
    });

    return () => {
      setStopsState({
        isError: false,
        isLoading: false,
        isSuccess: false,
      });
    };
  }, [getStopsQuery.isError, getStopsQuery.isLoading, getStopsQuery.isSuccess, setStopsState]);

  return null;
};

export default GetStops;
