import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles?: (FactBasicTruck | FactBasicBus)[];
}

const useEngineGearboxes = ({ basicVehicles }: Props) =>
  useMemo(
    () =>
      basicVehicles
        ? [
            ...new Set(
              basicVehicles
                .map((basicVehicle) => basicVehicle.EngineGearboxes?.map((engineGearbox) => engineGearbox.Name))
                .flat(),
            ),
          ].sort()
        : [],
    [basicVehicles],
  );

export default useEngineGearboxes;
