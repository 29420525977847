import * as XLSX from 'xlsx';
import { CandidateHandler } from 'src/features/candidate/Candidates/utils/useCandidatesHandler';
import { roundTo } from '@optimization/ssi-common';
import { useCallback, useMemo, useState } from 'react';
import { useGetPresentationDataQuery, useGetSolutionQuery } from 'app/services/solution';
import {
  chassisHeightTranslate,
  chassisAdaptationTranslate,
  calculateTotalEnergyConsumption,
  AVG_SPEED_DEFAULT,
  CRUISE_SPEED_DEFAULT,
  PresentationExportVehicle,
  PresentationExportDepot,
  trafficTranslate,
  topographyTranslate,
} from '@optimization/sa-common';

interface Result {
  disabled: boolean;
  isError: boolean;
  runExport: () => void;
}

const useExportSolutionHandler = ({
  solutionId,
  candidatesHandler,
}: {
  solutionId: string;
  candidatesHandler: CandidateHandler;
}): Result => {
  const [isWriteFileError, setIsWriteFileError] = useState(false);

  const solutionQuery = useGetSolutionQuery(solutionId);

  const filteredChassisList = useMemo(() => {
    if (candidatesHandler.filteredCandidates) return candidatesHandler.filteredCandidates?.map((e) => e.Id);

    return [];
  }, [candidatesHandler.filteredCandidates]);

  const exportQuery = useGetPresentationDataQuery({ solutionId, FilteredVehicleIds: filteredChassisList });

  const disabled = solutionQuery.isLoading || candidatesHandler.isLoading || exportQuery.isLoading;

  const isQueryError = solutionQuery.isError || candidatesHandler.isError || exportQuery.isError;

  const dataIsLoaded = solutionQuery.isSuccess && candidatesHandler.isSuccess && exportQuery.isSuccess;

  const runExport = useCallback(() => {
    if (dataIsLoaded) {
      try {
        const performanceSteps = exportQuery.data.Content.PerformanceSteps;
        const vehicleList = exportQuery.data.Content.Vehicles;
        setIsWriteFileError(false);

        const workbook = XLSX.utils.book_new();

        const vehicleWorksheet = XLSX.utils.json_to_sheet(getVehicleSheetData(performanceSteps, vehicleList), {
          header: Array.from({ length: Object.keys(vehicleSheetColumns).length }, (_, i) => `${i + 1}`),
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(workbook, vehicleWorksheet, 'Vehicle Candidates');

        const EvaluatedVehicleWorksheet = XLSX.utils.json_to_sheet(
          getEvaluatedChassisSheetData(performanceSteps, vehicleList),
          {
            header: Array.from({ length: Object.keys(evaluatedChassisColumns).length }, (_, i) => `${i + 1}`),
            skipHeader: true,
          },
        );
        XLSX.utils.book_append_sheet(workbook, EvaluatedVehicleWorksheet, 'Evaluated Chassis');

        const depotList = exportQuery.data.Content.Depots;

        const depotWorksheet = XLSX.utils.json_to_sheet(getDepotsSheetData(performanceSteps, depotList), {
          header: Array.from({ length: Object.keys(depotSheetColumns).length }, (_, i) => `${i + 1}`),
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(workbook, depotWorksheet, 'Depots');

        XLSX.writeFile(workbook, `${solutionQuery.data.transformed.solutionNamePresentation}.xlsx`);
      } catch (_) {
        setIsWriteFileError(true);
      }
    }
  }, [dataIsLoaded, solutionQuery, exportQuery]);

  return useMemo(
    () => ({
      disabled,
      isError: isQueryError || isWriteFileError,
      runExport,
    }),
    [disabled, isQueryError, isWriteFileError, runExport],
  );
};

export default useExportSolutionHandler;

const vehicleSheetColumns = {
  1: 'Performance Step',
  2: 'Name',
  3: 'Chassis type',
  4: 'Status',
  5: 'Launch Period',
  6: 'Designation',
  7: 'Electric Motor',
  8: 'Engine Power',
  9: 'Installed Energy',
  10: 'Charging Power',
  11: 'Soc Window',
  12: 'Useable Energy',
  13: 'Chassis adaptation',
  14: 'Wheel configuration',
  15: 'Axle distance',
  16: 'Chassis height',
  17: 'Cab type',
  18: 'Net EC',
  19: 'PTO EC',
  20: 'Total EC',
  21: 'Average GTW',
  22: 'Average Speed',
  23: 'Cruising Speed',
  24: 'Daily Base Range',
  25: 'Additional range from charging',
  26: 'Daily max. range',
  27: 'Daily Distance (median day)',
  28: 'Daily Distance (longest day)',
  29: 'Max. Temperature',
  30: 'Min Temperature',
  31: 'Topography',
  32: 'Traffic',
  33: 'Cycled Energy',
  34: 'Time to 80% SoH',
  35: 'C02 Savings',
  36: 'Body Length',
  37: 'Floor Height',
  38: 'PTO Device',
  39: 'Mapped to Depot',
};

const addVehicleCandidateToSheet = (
  performanceStep: number | null,
  result: {
    [key: string]: string | number | undefined;
  }[],
  vehicle: PresentationExportVehicle,
) => {
  const electricMotorRegex = /(?<=\d{3}\s).*/;
  const electricMotor = vehicle.ExecutedVehicle?.EngineGearbox
    ? electricMotorRegex.exec(vehicle.ExecutedVehicle?.EngineGearbox.Name)
    : null;

  const vehicleName = vehicle.ExecutedVehicle?.Name
    ? vehicle.ExecutedVehicle?.Name
    : vehicle.ScaniaChassis?.ChassisNumber;

  result.push({
    1: performanceStep ?? '',
    2: vehicleName || 'Manual',
    3: vehicle.ExecutedVehicle?.ChassisType ? vehicle.ExecutedVehicle?.ChassisType : 'Truck',
    4: vehicle.ExecutedVehicle?.Status,
    5: vehicle.ExecutedVehicle?.Period,
    6: vehicle.ExecutedVehicle?.Designation || '',
    7: electricMotor ? electricMotor[0] : '',
    8: vehicle.ExecutedVehicle?.EngineGearbox?.Power ? `${vehicle.ExecutedVehicle?.EngineGearbox?.Power} kW` : '',
    9: vehicle.ExecutedVehicle?.InstalledEnergyKwH ? `${vehicle.ExecutedVehicle?.InstalledEnergyKwH} kWh` : '',
    10: vehicle.ExecutedVehicle?.ChargingSpecification
      ? `${vehicle.ExecutedVehicle.ChargingSpecification?.ChargingType} - ${vehicle.ExecutedVehicle.ChargingSpecification?.ChargingPowerKW} kW`
      : '',
    11: vehicle.ExecutedVehicle?.Soc?.SocWindow ? `${vehicle.ExecutedVehicle?.Soc?.SocWindow} %` : '',
    12: vehicle.ExecutedVehicle?.Soc?.UsableEnergyKWh ? `${vehicle.ExecutedVehicle?.Soc?.UsableEnergyKWh} kWh` : '',
    13: vehicle.ExecutedVehicle?.ChassisAdaptation
      ? chassisAdaptationTranslate[vehicle.ExecutedVehicle?.ChassisAdaptation]
      : '',
    14: vehicle.ExecutedVehicle?.WheelConfiguration,
    15: vehicle.ExecutedVehicle?.AxleDistanceMm ? `${vehicle.ExecutedVehicle?.AxleDistanceMm} mm` : '',
    16: vehicle.ExecutedVehicle?.ChassisHeight ? chassisHeightTranslate[vehicle.ExecutedVehicle?.ChassisHeight] : '',
    17: vehicle.ExecutedVehicle?.Cab,
    18: vehicle.ExecutedVehicle?.NetEc ? `${roundTo(vehicle.ExecutedVehicle?.NetEc, 2)} kWh/km` : 0,
    19: vehicle.ExecutedVehicle?.PtoEc ? `${roundTo(vehicle.ExecutedVehicle?.PtoEc, 2)} kWh/km` : 0,
    20: `${roundTo(calculateTotalEnergyConsumption(vehicle.ExecutedVehicle?.NetEc || 0, vehicle.ExecutedVehicle?.PtoEc || 0), 2)} kWh/km`,
    21: vehicle.ExecutedVehicle?.AvgGtwKg ? `${roundTo(vehicle.ExecutedVehicle?.AvgGtwKg / 1000, 1)} t` : '',
    22: `${roundTo(vehicle.ExecutedVehicle?.AvgSpeedKmh || AVG_SPEED_DEFAULT / 1000, 0)} km/h`,
    23: `${roundTo(vehicle.ExecutedVehicle?.CruiseSpeedKmh || CRUISE_SPEED_DEFAULT / 1000, 0)} km/h`,
    24: `${vehicle.ExecutedVehicle?.BaseRange} km`,
    25: `${vehicle.ExecutedVehicle?.AdditionalRange} km`,
    26: `${vehicle.ExecutedVehicle?.DailyRange} km`,
    27: vehicle.ExecutedVehicle?.DailyOdometerMedianKm
      ? `${roundTo(vehicle.ExecutedVehicle?.DailyOdometerMedianKm, 0)} km`
      : '',
    28: vehicle.ExecutedVehicle?.DailyOdometerLongestKm
      ? `${roundTo(vehicle.ExecutedVehicle?.DailyOdometerLongestKm, 0)} km`
      : '',
    29: `${vehicle.ExecutedVehicle?.MaximumTemperature} C`,
    30: `${vehicle.ExecutedVehicle?.MinimumTemperature} C`,
    31: vehicle.ExecutedVehicle?.Topography ? topographyTranslate[vehicle.ExecutedVehicle?.Topography] : '',
    32: vehicle.ExecutedVehicle?.Traffic ? trafficTranslate[vehicle.ExecutedVehicle?.Traffic] : '',
    33: vehicle.ExecutedVehicle?.CycledEnergy ? `${roundTo(vehicle.ExecutedVehicle?.CycledEnergy, 1)} kWh/km` : '',
    34: `${roundTo(vehicle.TimeToEightyPercentSoH, 1)} years`,
    35: vehicle.Co2Savings,
    36: vehicle.ExecutedVehicle?.BodyLengthM ? `${vehicle.ExecutedVehicle?.BodyLengthM} m` : '',
    37: vehicle.ExecutedVehicle?.BusFloorHeight ? `${vehicle.ExecutedVehicle?.BusFloorHeight}` : '',
    38: vehicle.ExecutedVehicle?.ExecutedVehicleProductPTOs.map((pto) => `${pto.Type}: ${pto.Value}`).join(', '),
    39: vehicle.DepotName || '',
  });
};

const getVehicleSheetData = (performanceSteps: number[], vehicleList: PresentationExportVehicle[]) => {
  let result: { [key: string]: string | number | undefined }[] = [{ 1: 'VEHICLES' }];
  result.push(vehicleSheetColumns);

  for (const performanceStep of performanceSteps) {
    for (const vehicle of vehicleList.filter((x) => x.ExecutedVehicle?.PerformanceStepYear === performanceStep)) {
      addVehicleCandidateToSheet(performanceStep, result, vehicle);
    }
    result.push({});
  }

  const nextGenerationVehicles = vehicleList.filter((x) => !x.ExecutedVehicle?.PerformanceStepYear);

  if (nextGenerationVehicles.length > 0) result.push({ 1: 'NEXT GENERATION' });
  for (const vehicle of nextGenerationVehicles) addVehicleCandidateToSheet(null, result, vehicle);

  return result;
};

const evaluatedChassisColumns = {
  1: 'Chassis Number',
  2: 'Evaluation Date',
  3: 'Assembly Date',
  4: 'Chassis Adaptation',
  5: 'Wheel configuration',
  6: 'AxleDistance',
  7: 'Chassis height',
  8: 'Cab type',
  9: 'Daily distance (5th Percentile)',
  10: 'Daily distance (25th Percentile)',
  11: 'Daily distance (Median)',
  12: 'Daily distance (75th Percentile)',
  13: 'Daily distance (95th Percentile)',
  14: 'Daily avg. GTW (5th Percentile)',
  15: 'Daily avg. GTW (25th Percentile)',
  16: 'Daily avg. GTW (Median)',
  17: 'Daily avg. GTW (75th Percentile)',
  18: 'Daily avg. GTW (95th Percentile)',
  19: 'Max.temperature',
  20: 'Min.temperature',
  21: 'Topography',
  22: 'Traffic',
  23: 'Fuel/Gas consumption',
};

const addEvaluatedChassisToSheet = (
  result: {
    [key: string]: string | number | undefined;
  }[],
  vehicle: PresentationExportVehicle,
) => {
  const fuelSuffix = vehicle.ScaniaChassis?.FuelType === 'Diesel' ? 'l/km (Diesel)' : 'kg/km (Gas)';
  result.push({
    1: vehicle.ScaniaChassis?.ChassisNumber,
    2: vehicle.ScaniaChassis?.EvaluationDate,
    3: vehicle.ScaniaChassis?.AssemblyDate,
    4: vehicle.ScaniaChassis?.ChassisAdaptation
      ? chassisAdaptationTranslate[vehicle.ScaniaChassis?.ChassisAdaptation]
      : '',
    5: vehicle.ScaniaChassis?.WheelConfiguration,
    6: vehicle.ScaniaChassis?.AxleDistanceMm ? `${vehicle.ScaniaChassis?.AxleDistanceMm} mm` : '',
    7: vehicle.ScaniaChassis?.ChassisHeight ? chassisHeightTranslate[vehicle.ScaniaChassis?.ChassisHeight] : '',
    8: vehicle.ScaniaChassis?.Cab,
    9: vehicle.ScaniaChassis?.DailyOdometer05Km ? `${roundTo(vehicle.ScaniaChassis?.DailyOdometer05Km, 0)} km` : '',
    10: vehicle.ScaniaChassis?.DailyOdometerQ1Km ? `${roundTo(vehicle.ScaniaChassis?.DailyOdometerQ1Km, 0)} km` : '',
    11: vehicle.ScaniaChassis?.DailyOdometerMedianKm
      ? `${roundTo(vehicle.ScaniaChassis?.DailyOdometerMedianKm, 0)} km`
      : '',
    12: vehicle.ScaniaChassis?.DailyOdometerQ3Km ? `${roundTo(vehicle.ScaniaChassis?.DailyOdometerQ3Km, 0)} km` : '',
    13: vehicle.ScaniaChassis?.DailyOdometer95Km ? `${roundTo(vehicle.ScaniaChassis?.DailyOdometer95Km, 0)} km` : '',
    14: vehicle.ScaniaChassis?.DailyAvgGtw05 ? `${roundTo(vehicle.ScaniaChassis?.DailyAvgGtw05, 0)} kg` : '',
    15: vehicle.ScaniaChassis?.DailyAvgGtw25 ? `${roundTo(vehicle.ScaniaChassis?.DailyAvgGtw25, 0)} kg` : '',
    16: vehicle.ScaniaChassis?.DailyAvgGtwMedian ? `${roundTo(vehicle.ScaniaChassis?.DailyAvgGtwMedian, 0)} kg` : '',
    17: vehicle.ScaniaChassis?.DailyAvgGtw75 ? `${roundTo(vehicle.ScaniaChassis?.DailyAvgGtw75, 0)} kg` : '',
    18: vehicle.ScaniaChassis?.DailyAvgGtw95 ? `${roundTo(vehicle.ScaniaChassis?.DailyAvgGtw95, 0)} kg` : '',
    19: vehicle.ScaniaChassis?.MaximumTemperature ? `${vehicle.ScaniaChassis?.MaximumTemperature} C` : '',
    20: vehicle.ScaniaChassis?.MinimumTemperature ? `${vehicle.ScaniaChassis?.MinimumTemperature} C` : '',
    21: vehicle.ScaniaChassis?.Topography ? topographyTranslate[vehicle.ScaniaChassis?.Topography] : '',
    22: vehicle.ScaniaChassis?.Traffic ? trafficTranslate[vehicle.ScaniaChassis?.Traffic] : '',
    23: vehicle.ScaniaChassis?.ConsumptionPerKm
      ? `${roundTo(vehicle.ScaniaChassis?.ConsumptionPerKm, 2)} ${fuelSuffix}`
      : '',
  });
};

const getEvaluatedChassisSheetData = (performanceSteps: number[], vehicleList: PresentationExportVehicle[]) => {
  let result: { [key: string]: string | number | undefined }[] = [{ 1: 'VEHICLES' }];
  result.push(evaluatedChassisColumns);

  for (const performanceStep of performanceSteps) {
    for (const vehicle of vehicleList.filter(
      (x) => x.ExecutedVehicle?.PerformanceStepYear === performanceStep && x.ScaniaChassis?.ChassisNumber,
    )) {
      addEvaluatedChassisToSheet(result, vehicle);
    }
    result.push({});
  }
  const nextGenerationVehicles = vehicleList.filter(
    (x) => !x.ExecutedVehicle?.PerformanceStepYear && x.ScaniaChassis?.ChassisNumber,
  );

  if (nextGenerationVehicles.length > 0) result.push({ 1: 'NEXT GENERATION' });
  for (const vehicle of nextGenerationVehicles) addEvaluatedChassisToSheet(result, vehicle);

  return result;
};

const depotSheetColumns = {
  1: 'Depot',
  2: 'Performance step',
  3: 'Charging model',
  4: 'Power',
  5: 'Connectors',
  6: 'Quantity',
};

const getDepotsSheetData = (performanceSteps: number[], depotList: PresentationExportDepot[]) => {
  let result: { [key: string]: string | number | undefined }[] = [{ 1: 'DEPOT INFRASTRUCTURE' }];
  result.push(depotSheetColumns);

  for (const depot of depotList) {
    for (const performanceStep of performanceSteps)
      for (const charger of depot.Chargers.filter((x) => x.PerformanceStep === performanceStep))
        result.push({
          1: depot.Name,
          2: charger.PerformanceStep,
          3: `${charger.Brand} (${charger.Solution})`,
          4: charger.Power,
          5: charger.Satellites,
          6: charger.Quantity,
        });

    result.push({});
  }

  return result;
};
