interface Props {
  count?: number;
  uppercase?: boolean;
}

const usePlural = ({ count, uppercase = false }: Props) => {
  if (typeof count !== 'number') {
    return '';
  }

  const plural = uppercase ? 'S' : 's';

  return count === 1 ? '' : plural;
};

export default usePlural;
