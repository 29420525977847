import CandidatesTable from 'features/candidate/Candidates/Table';
import DepotModal from './DepotModal';
import DepotTeaser from 'features/infrastructure/DepotTeaser';
import GetEnhancedDepot from 'common/components/GetEnhancedDepot';
import Grid from 'common/components/Grid';
import HelmetTitle from 'common/components/HelmetTitle';
import LoadMore from 'common/components/LoadMore';
import PageHeaderAlt from 'common/components/PageHeaderAlt';
import PageNotFound from 'common/components/PageNotFound';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useDepotYear from 'features/infrastructure/utils/useDepotYear';
import useLoadingText from 'common/hooks/useLoadingText';
import useNavigateToYearByTabIndex from './utils/useNavigateToYearByTabIndex';
import usePerformanceStepDepot from './utils/usePerformanceStepDepot';
import usePerformanceStepIndex from './utils/usePerformanceStepIndex';
import useSelectedPerformanceStep from './utils/useSelectedPerformanceStep';
import useSolutionPerformanceSteps from 'features/presentation/PerformanceSteps/Steps/utils/useSolutionPerformanceSteps';
import useSplicedDepots from './utils/useSplicedDepots';
import useSplicedListVehicles from './utils/useSplicedListVehicles';
import { InlineTabs, TInlineTabs } from '@optimization/ssi-common';
import { invariant, Loading } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMakeElementsSticky } from 'app/context/StickyHandlerContext';
import { useParams } from 'react-router-dom';
import { useGetEnhancedDepotsHandler, useVehicleSettingsHandler } from '@optimization/sa-common';
import {
  useGetSolutionQuery,
  useGetDepotsQuery,
  useGetFactBasicDataQuery,
  useGetVehiclesQuery,
} from 'app/services/solution';

const entityTypeTabs = [
  {
    name: 'Vehicles',
  },
  {
    name: 'Depots',
  },
];

const VEHICLES_TAB = 0;
const DEPOTS_TAB = 1;

const SolutionPresentationPerformanceStepDetail = () => {
  const { solutionId, yearParam } = useParams();

  invariant(solutionId);
  invariant(yearParam);

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const solutionQuery = useGetSolutionQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);
  const vehiclesInSolutionQuery = useGetVehiclesQuery(solutionId);

  const yearSwitcherTabsRef = useRef<HTMLDivElement | null>(null);

  const getEnhancedDepotsHandler = useGetEnhancedDepotsHandler();

  const [entityTypeTabIndex, setEntityTypeTabIndex] = useState(VEHICLES_TAB);

  const [depotId, setDepotId] = useState<number | null>(null);

  const [vehiclesCount, setVehiclesCount] = useState<number | undefined>(undefined);

  const [depotsCount, setDepotsCount] = useState<number | undefined>(undefined);

  const depot = useMemo(() => depotsQuery.data?.find((item) => item.DepotId === depotId), [depotsQuery, depotId]);

  const depotYear = useDepotYear({
    depotDefaultYear: depot?.transformed.depotDefaultYear,
    yearParam,
  });

  const vehicleSettingsHandler = useVehicleSettingsHandler();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    vehicleSettingsById: vehicleSettingsHandler.vehicleSettingsById,
  });

  const performanceSteps = useSolutionPerformanceSteps({
    factBasicDataResponse: factBasicDataQuery.data,
    vehiclesInSolution: candidatesHandler.filteredCandidates,
    depotsInSolution: depotsQuery.data,
    getEnhancedDepotsHandler,
  });

  const yearTabs: TInlineTabs<number> = useMemo(
    () =>
      performanceSteps.map((performanceStep) => ({
        name: performanceStep.year,
      })),
    [performanceSteps],
  );

  const navigateToYearByTabIndex = useNavigateToYearByTabIndex({
    solutionId,
    performanceSteps,
  });

  const closeDepotModal = useCallback(() => {
    setDepotId(null);
  }, []);

  useEffect(() => {
    candidatesHandler.setVehiclesCount(candidatesHandler.filteredCandidates?.length);
  }, [candidatesHandler]);

  const selectedPerformanceStep = useSelectedPerformanceStep({
    performanceSteps,
    yearParam,
  });

  const performanceStepIndex = usePerformanceStepIndex({
    performanceSteps,
    selectedPerformanceStep,
  });

  const splicedListVehicles = useSplicedListVehicles({
    selectedPerformanceStep,
    vehiclesCount,
  });

  const splicedDepots = useSplicedDepots({
    selectedPerformanceStep,
    depotsCount,
  });

  const performanceStepDepot = usePerformanceStepDepot({
    selectedPerformanceStep,
    depot,
  });

  useMakeElementsSticky({
    yearSwitcherTabsRef: performanceStepIndex > -1 ? yearSwitcherTabsRef : undefined,
  });

  const isLoading =
    solutionQuery.isLoading ||
    candidatesHandler.isLoading ||
    depotsQuery.isLoading ||
    factBasicDataQuery.isLoading ||
    getEnhancedDepotsHandler.isLoading;

  const isError =
    solutionQuery.isError ||
    candidatesHandler.isError ||
    depotsQuery.isError ||
    factBasicDataQuery.isError ||
    getEnhancedDepotsHandler.isError;

  const dataIsLoaded =
    factBasicDataQuery.isSuccess &&
    solutionQuery.isSuccess &&
    candidatesHandler.isSuccess &&
    depotsQuery.isSuccess &&
    getEnhancedDepotsHandler.isSuccess;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates:
      candidatesHandler.isLoading || vehiclesInSolutionQuery.isLoading || factBasicDataQuery.isFetching,
    isLoadingDepots: depotsQuery.isLoading || getEnhancedDepotsHandler.isLoading,
  });

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Presentation performance step', yearParam]} />
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {performanceStepIndex > -1 && (
        <PageHeaderAlt
          backLinkText="Overview"
          backLinkUrl={`/solution/${solutionId}/presentation/performance-steps`}
          tabs={yearTabs}
          tabsRef={yearSwitcherTabsRef}
          tabIndex={performanceStepIndex}
          setTabIndex={navigateToYearByTabIndex}
        >
          Performance step
        </PageHeaderAlt>
      )}
      <div className="tds-container mt-spacing-44">
        {performanceStepDepot && (
          <DepotModal
            depot={performanceStepDepot}
            depotYear={depotYear}
            solutionId={solutionId}
            onClose={closeDepotModal}
          />
        )}
        {dataIsLoaded && (
          <>
            {selectedPerformanceStep?.depots && selectedPerformanceStep?.depots?.length > 0 && (
              <InlineTabs tabs={entityTypeTabs} tabIndex={entityTypeTabIndex} setTabIndex={setEntityTypeTabIndex} />
            )}
            {entityTypeTabIndex === VEHICLES_TAB && selectedPerformanceStep && (
              <>
                <CandidatesTable
                  solutionId={solutionId}
                  vehicles={splicedListVehicles}
                  setIsWinter={vehicleSettingsHandler.setIsWinter}
                  setIsSoH80={vehicleSettingsHandler.setIsSoH80}
                  setShowTopUp={vehicleSettingsHandler.setShowTopUp}
                  enableYearSelectorForChargingEvents
                  performanceStepDropdownReadonly
                  actionMode="expandable-rows"
                  candidateTabDefault="Candidate specs"
                  className="tds-u-mt2"
                  dataTestid="performance-step-detail-table"
                  vehicleSettingsById={vehicleSettingsHandler.vehicleSettingsById}
                />
                <LoadMore
                  contentName="Vehicles"
                  itemsCount={splicedListVehicles.length}
                  totalCount={selectedPerformanceStep.vehicles.length}
                  itemsPerPage={8}
                  setItemsCount={setVehiclesCount}
                />
              </>
            )}
            {entityTypeTabIndex === DEPOTS_TAB && splicedDepots && (
              <>
                <Grid className="tds-u-mt2">
                  {splicedDepots.map((depot) => (
                    <DepotTeaser
                      key={depot.Id}
                      solutionId={solutionId}
                      depot={depot}
                      onClick={() => setDepotId(depot.Id)}
                      solutionNamePresentation={solutionQuery.data?.transformed.solutionNamePresentation}
                    />
                  ))}
                </Grid>
                <LoadMore
                  contentName="Depots"
                  itemsCount={splicedDepots.length}
                  totalCount={selectedPerformanceStep?.depots?.length}
                  itemsPerPage={8}
                  setItemsCount={setDepotsCount}
                />
              </>
            )}
          </>
        )}
      </div>
      {depotsQuery.data &&
        depotsQuery.data.map((depot) => (
          <GetEnhancedDepot
            key={depot.DepotId}
            solutionId={solutionId}
            depotId={depot.DepotId}
            vehiclesInSolution={vehiclesInSolutionQuery.data}
            setEnhancedDepot={getEnhancedDepotsHandler.setEnhancedDepot}
          />
        ))}
    </>
  );
};

export default SolutionPresentationPerformanceStepDetail;
