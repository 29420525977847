import { ListVehicleEnhanced, SALES_READY_TEXT } from '@optimization/sa-common';
import { useMemo } from 'react';

export interface ShowcaseLaunchPeriod {
  launchPeriodPresentationAlt: string;
  chassisType: string;
}

const useShowcaseLaunchPeriods = (vehicles?: ListVehicleEnhanced[]): ShowcaseLaunchPeriod[] =>
  useMemo(() => {
    if (!vehicles) {
      return [];
    }

    const result = new Map<string, ShowcaseLaunchPeriod>();

    const filteredVehicles = vehicles.filter((vehicle) => vehicle.transformed.launchPeriodPresentationAlt);

    for (const vehicle of filteredVehicles) {
      const item = {
        launchPeriodPresentationAlt: vehicle.transformed.launchPeriodPresentationAlt,
        chassisType: vehicle.ChassisType || 'Truck',
      };

      const key = `${item.chassisType}##${item.launchPeriodPresentationAlt}`;

      if (!result.has(key)) {
        result.set(key, item);
      }
    }

    return Array.from(result.values()).sort((a, b) => {
      // Sort trucks before buses
      if (a.chassisType !== b.chassisType) {
        return a.chassisType === 'Truck' ? -1 : 1;
      }

      // Sort by launchPeriodPresentationAlt, prioritizing sales ready first
      const aIsSalesReady = a.launchPeriodPresentationAlt.includes(SALES_READY_TEXT);
      const bIsSalesReady = b.launchPeriodPresentationAlt.includes(SALES_READY_TEXT);

      if (aIsSalesReady && !bIsSalesReady) return -1;
      if (!aIsSalesReady && bIsSalesReady) return 1;

      // Sort alphabetically if neither has sales ready
      return a.launchPeriodPresentationAlt.localeCompare(b.launchPeriodPresentationAlt);
    });
  }, [vehicles]);

export default useShowcaseLaunchPeriods;
