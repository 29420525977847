import Main from './Main';
import useLoadingText from 'common/hooks/useLoadingText';
import { Loading } from '@optimization/ssi-common';
import {
  ListVehicleTransformed,
  VehicleSettings,
  SetIsWinter,
  SetIsSoH80,
  SetShowTopUp,
  vehicleSettingsDefault,
} from '@optimization/sa-common';
import { useGetFactBasicDataQuery, useGetVehicleQuery } from 'app/services/solution';

export type CandidateTabs =
  | 'Charging events'
  | 'Historical stops'
  | 'Environmental impact'
  | 'Financial evaluation'
  | 'Candidate specs';

interface Props {
  className?: string;
  candidateTabDefault?: CandidateTabs;
  enableYearSelectorForChargingEvents: boolean;
  solutionId: string;
  tooltipKey: number;
  expanded?: boolean;
  vehicle: ListVehicleTransformed;
  vehicleSettings?: VehicleSettings;
  setIsWinter: SetIsWinter;
  setIsSoH80: SetIsSoH80;
  setShowTopUp: SetShowTopUp;
}

const CandidateDetail = ({
  className,
  candidateTabDefault = 'Candidate specs',
  enableYearSelectorForChargingEvents,
  solutionId,
  tooltipKey,
  vehicle,
  expanded,
  vehicleSettings = vehicleSettingsDefault,
  setIsWinter,
  setIsSoH80,
  setShowTopUp,
}: Props) => {
  const vehicleQuery = useGetVehicleQuery(
    {
      solutionId,
      vehicleId: vehicle.Id,
    },
    { skip: !expanded },
  );

  const factBasicDataQuery = useGetFactBasicDataQuery();

  const isLoading = vehicleQuery.isLoading || factBasicDataQuery.isLoading;
  const isError = vehicleQuery.isError || factBasicDataQuery.isError;

  const loadingText = useLoadingText({
    isLoadingCandidate: vehicleQuery.isLoading || factBasicDataQuery.isLoading,
  });

  return (
    <>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      {vehicleQuery.data && factBasicDataQuery.data && (
        <Main
          className={className}
          factBasicDataQueryData={factBasicDataQuery.data}
          candidateTabDefault={candidateTabDefault}
          solutionId={solutionId}
          tooltipKey={tooltipKey}
          vehicle={vehicleQuery.data}
          vehicleSettings={vehicleSettings}
          setIsWinter={setIsWinter}
          setIsSoH80={setIsSoH80}
          setShowTopUp={setShowTopUp}
          enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
        />
      )}
    </>
  );
};

export default CandidateDetail;
