import { SolutionPerformanceStep } from '../Steps/utils/types';
import { useEffect } from 'react';

const isOverflown = ({ scrollWidth, clientWidth }: { scrollWidth: number; clientWidth: number }) =>
  scrollWidth > clientWidth;

const useSetVerticalScrollToHorizontal = (performanceSteps: SolutionPerformanceStep[]) => {
  useEffect(() => {
    if (performanceSteps.length === 0) return;
    const transformScroll = (event: any) => {
      if (!event.deltaY) {
        return;
      }

      event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
      event.preventDefault();
    };

    const horizontalTransform = document.getElementsByClassName('horizontal-scroll-transform');

    for (let i = 0; i < horizontalTransform.length; i++) {
      if (
        isOverflown({
          scrollWidth: horizontalTransform[i].scrollWidth,
          clientWidth: horizontalTransform[i].clientWidth,
        })
      ) {
        horizontalTransform[i].addEventListener('wheel', transformScroll);
      }
    }

    return () => {
      for (let i = 0; i < horizontalTransform.length; i++) {
        horizontalTransform[i].removeEventListener('wheel', transformScroll);
      }
    };
  }, [performanceSteps.length]);
};

export default useSetVerticalScrollToHorizontal;
