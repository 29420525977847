import classes from './style.module.scss';
import classNames from 'classnames';
import { Box } from '@optimization/sa-common';

interface Props {
  header: string;
  children: string;
}

const UniqueSellingPoint = ({ header, children }: Props) => (
  <Box className={classes['unique-selling-point']}>
    <h1 className={classNames('tds-headline-01', classes.header)}>{header}</h1>
    <p className={classNames('tds-headline-04', classes.text)}>{children}</p>
  </Box>
);

export default UniqueSellingPoint;
