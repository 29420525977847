import { FactBasicBus } from '@optimization/sa-common';
import { getLaunchPeriodPresentationAlt } from '@optimization/sa-common';
import { useMemo } from 'react';

interface UseFilteredBussesProps {
  basicBusses: FactBasicBus[];
  launchPeriodPresentationAlt: string;
  statusTabIndex: number;
  chassisType: string;
}

const useFilteredBusses = ({
  basicBusses,
  launchPeriodPresentationAlt,
  statusTabIndex,
  chassisType,
}: UseFilteredBussesProps) => {
  const bussesInLaunchPeriod = useMemo(
    () =>
      (chassisType === 'Bus' &&
        basicBusses.filter(
          (basicBus) => getLaunchPeriodPresentationAlt(basicBus.Period) === launchPeriodPresentationAlt,
        )) ||
      [],
    [chassisType, basicBusses, launchPeriodPresentationAlt],
  );

  const busStatus = useMemo(
    () => [...new Set(bussesInLaunchPeriod.map((basicTruck) => basicTruck.Status).reverse())],
    [bussesInLaunchPeriod],
  );

  const bussesInStatus = useMemo(
    () => bussesInLaunchPeriod.filter((basicTruck) => basicTruck.Status === busStatus[statusTabIndex]) || [],
    [bussesInLaunchPeriod, busStatus, statusTabIndex],
  );

  return {
    busStatus,
    bussesInStatus,
  };
};

export default useFilteredBusses;
