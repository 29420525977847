import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  performanceSteps: SolutionPerformanceStep[];
  selectedPerformanceStep?: SolutionPerformanceStep;
}

const usePerformanceStepIndex = ({ performanceSteps, selectedPerformanceStep }: Props) =>
  useMemo(
    () =>
      selectedPerformanceStep
        ? performanceSteps.findIndex((performanceStep) => performanceStep.year === selectedPerformanceStep.year)
        : -1,
    [performanceSteps, selectedPerformanceStep],
  );

export default usePerformanceStepIndex;
