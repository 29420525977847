import classes from './style.module.scss';
import { CountBox } from '@optimization/sa-common';
import { TdsIcon } from '@scania/tegel-react';

interface Props {
  distanceElectrified: number;
}

const DistanceElectrified = ({ distanceElectrified }: Props) => (
  <CountBox
    className={classes['distance-electrified']}
    icon={<TdsIcon name="truck" size="32px" className="mr-spacing-8" />}
    header="Distance electrified"
    subHeader="Estimated km"
    count={`${distanceElectrified}K`}
  />
);

export default DistanceElectrified;
