import classes from './style.module.scss';
import { CountBox } from '@optimization/sa-common';

interface Props {
  depotsCount: number;
}

const Depots = ({ depotsCount }: Props) => (
  <CountBox
    header="Depots"
    subHeader="Required for transformation"
    count={depotsCount}
    countClassName={classes.count}
  />
);

export default Depots;
