import React, { useMemo } from 'react';
import SolutionStops from './SolutionStops';
import { ListVehicleEnhanced, SolutionTransformed } from '@optimization/sa-common';

interface SolutionContentProps {
  solutionId: string;
  filteredCandidates?: ListVehicleEnhanced[];
  solution: SolutionTransformed;
}

const HistoricalStops: React.FC<SolutionContentProps> = ({ solutionId, filteredCandidates, solution }) => {
  const mapLocation = useMemo(
    () => solution?.MapLocationsV2?.filter((item) => item.Name === 'overview')?.[0],
    [solution],
  );

  const vehiclesAtLocation = useMemo(
    () =>
      mapLocation && filteredCandidates
        ? filteredCandidates.filter((vehicle) => mapLocation.VehicleIds.includes(vehicle.Id))
        : filteredCandidates,
    [mapLocation, filteredCandidates],
  );

  if (vehiclesAtLocation) {
    return (
      <SolutionStops solutionId={solutionId} enhancedListVehicles={vehiclesAtLocation} mapLocation={mapLocation} />
    );
  } else {
    return <div>Found no vehicles.</div>;
  }
};

export default HistoricalStops;
