import classes from './style.module.scss';
import classNames from 'classnames';
import React, { useId } from 'react';
import useElectrificationJourneyData from './useElectrificationJourneyData';
import { Box, useRenderChartTooltip } from '@optimization/sa-common';
import { Chart } from 'react-charts';
import { ListVehicleEnhanced } from '@optimization/sa-common';
import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';

interface Props {
  className?: string;
  performanceSteps: SolutionPerformanceStep[];
  filteredCandidates: ListVehicleEnhanced[];
}

const ElectrificationJourney: React.FC<Props> = ({ className, performanceSteps, filteredCandidates }) => {
  const linearGradientId = useId();

  const { primaryAxis, secondaryAxes, data } = useElectrificationJourneyData(performanceSteps, filteredCandidates);

  const renderChartTooltip = useRenderChartTooltip();

  return (
    <Box className={classNames(className, classes['electrification-journey'])}>
      <div>
        <h5 className="tds-headline-05">Electrification journey</h5>
        <div className="tds-body-01 mt-spacing-4">Yearly performance steps</div>
        <div className={classNames(classes['fleet-electrified'], classes['tds-detail-05'])}>
          <div className={classes.dot} />
          Fleet electrified
        </div>
      </div>
      <div style={{ minHeight: '296px' }} className="mt-spacing-16">
        <Chart
          options={{
            data,
            primaryAxis,
            memoizeSeries: true,
            secondaryAxes,
            defaultColors: ['#08244C'],
            primaryCursor: { show: false },
            secondaryCursor: { show: false },
            tooltip: { show: true, render: renderChartTooltip },
            renderSVG: () => (
              <defs>
                <linearGradient id={linearGradientId} x1="0" x2="0" y1="1" y2="0">
                  <stop offset="0%" stopColor="rgba(8, 36, 76, 0.02" />
                  <stop offset="100%" stopColor="rgba(8, 36, 76, 1)" />
                </linearGradient>
              </defs>
            ),
            getSeriesStyle: () => ({
              color: `url(#${linearGradientId})`,
              stroke: '#08244C',
              strokeWidth: 2,
            }),
          }}
        />
      </div>
    </Box>
  );
};

export default ElectrificationJourney;
