import GetEnhancedDepot from 'common/components/GetEnhancedDepot';
import HelmetTitle from 'common/components/HelmetTitle';
import PageHeaderAlt from 'common/components/PageHeaderAlt';
import PageNotFound from 'common/components/PageNotFound';
import SolutionPerformanceSteps from './Steps';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useLoadingText from 'common/hooks/useLoadingText';
import useNextGenVehicles from './utils/useNextGenVehicles';
import useSetVerticalScrollToHorizontal from './utils/useSetVerticalScrollToHorizontal';
import useSolutionPerformanceSteps from 'features/presentation/PerformanceSteps/Steps/utils/useSolutionPerformanceSteps';
import { invariant, Loading } from '@optimization/ssi-common';
import { isError404 } from 'common/utils/helper';
import { useEffect } from 'react';
import { useGetEnhancedDepotsHandler, useHomePath } from '@optimization/sa-common';
import { useParams } from 'react-router-dom';
import {
  useGetSolutionQuery,
  useGetDepotsQuery,
  useGetFactBasicDataQuery,
  useGetVehiclesQuery,
} from 'app/services/solution';

const SolutionPresentationPerformanceSteps = () => {
  const homePath = useHomePath();

  const { solutionId } = useParams();

  invariant(solutionId);

  const factBasicDataQuery = useGetFactBasicDataQuery();
  const solutionQuery = useGetSolutionQuery(solutionId);
  const depotsQuery = useGetDepotsQuery(solutionId);
  const vehiclesInSolutionQuery = useGetVehiclesQuery(solutionId);

  const getEnhancedDepotsHandler = useGetEnhancedDepotsHandler();
  const nextGenVehicles = useNextGenVehicles(vehiclesInSolutionQuery.data);

  const candidatesHandler = useCandidatesHandler({
    solutionId,
  });

  useEffect(() => {
    candidatesHandler.setVehiclesCount(candidatesHandler.filteredCandidates?.length);
  }, [candidatesHandler]);

  const performanceSteps = useSolutionPerformanceSteps({
    factBasicDataResponse: factBasicDataQuery.data,
    vehiclesInSolution: candidatesHandler.filteredCandidates,
    depotsInSolution: depotsQuery.data,
    getEnhancedDepotsHandler,
  });

  const isLoading =
    factBasicDataQuery.isLoading ||
    solutionQuery.isLoading ||
    candidatesHandler.isLoading ||
    depotsQuery.isLoading ||
    vehiclesInSolutionQuery.isLoading ||
    getEnhancedDepotsHandler.isLoading;

  const isError =
    factBasicDataQuery.isError ||
    solutionQuery.isError ||
    candidatesHandler.isError ||
    depotsQuery.isError ||
    vehiclesInSolutionQuery.isError ||
    getEnhancedDepotsHandler.isError;

  const loadingText = useLoadingText({
    isLoadingSolution: solutionQuery.isFetching,
    isLoadingCandidates:
      candidatesHandler.isLoading || vehiclesInSolutionQuery.isLoading || factBasicDataQuery.isFetching,
    isLoadingDepots: depotsQuery.isLoading || getEnhancedDepotsHandler.isLoading,
  });

  useSetVerticalScrollToHorizontal(performanceSteps);

  if (isError404(solutionQuery.error)) {
    return <PageNotFound />;
  }

  return (
    <>
      <HelmetTitle solutionId={solutionId} items={['Presentation performance steps']} />
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <PageHeaderAlt
        backLinkText="Back to home"
        backLinkUrl={homePath}
        description={[
          'Explore the solution in detail. Explore vehicle candidates, their charging schedules, and depot infrastructure for each year in the electrification plan.',
        ]}
      >
        Performance steps
      </PageHeaderAlt>
      <SolutionPerformanceSteps
        solutionId={solutionId}
        performanceSteps={performanceSteps}
        nextGenVehicles={nextGenVehicles}
      />
      {depotsQuery.data &&
        depotsQuery.data.map((depot) => (
          <GetEnhancedDepot
            key={depot.DepotId}
            solutionId={solutionId}
            depotId={depot.DepotId}
            vehiclesInSolution={vehiclesInSolutionQuery.data}
            setEnhancedDepot={getEnhancedDepotsHandler.setEnhancedDepot}
          />
        ))}
    </>
  );
};

export default SolutionPresentationPerformanceSteps;
