import { FinancialEvaluationVisual, useFinancialEvaluationChartData, VehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';
import { useCalculateVehicleTcoFiguresQuery } from 'src/app/services/solution';

export const vehicleTypeOptions = [
  { value: 'Bev', name: 'BEV' },
  { value: 'Diesel', name: 'Diesel' },
  { value: 'NaturalGas', name: 'Gas' },
];

interface props {
  vehicle: VehicleEnhanced;
  solutionId: string;
}

export const FinancialEvaluation = ({ vehicle, solutionId }: props) => {
  const presentEvaluation = useMemo(
    () => vehicle.TcoCalculations !== null && vehicle.TcoCalculations?.ShowInPresentationApp === true,
    [vehicle],
  );

  const vehicleCalculation = useCalculateVehicleTcoFiguresQuery(
    {
      solutionId: solutionId!,
      vehicleId: vehicle.Id,
      ReturnResidualValue: false,
      Currency: vehicle.TcoCalculations?.Currency ?? 'EUR',
      ShowInPresentationApp: vehicle.TcoCalculations?.ShowInPresentationApp ?? true,
      BevTcoFigures: vehicle.TcoCalculations?.BevVehicle!,
      ComparisonTcoFigures: vehicle.TcoCalculations?.ComparisonVehicle,
    },
    {
      skip:
        !presentEvaluation ||
        !vehicle.TcoCalculations?.BevVehicle ||
        (vehicle.TcoCalculations?.BevVehicle.FinancingType === 'Leasing' &&
          vehicle.TcoCalculations?.BevVehicle.InterestRate === 0) ||
        (vehicle.TcoCalculations?.ComparisonVehicle?.FinancingType === 'Leasing' &&
          vehicle.TcoCalculations?.ComparisonVehicle.InterestRate === 0) ||
        vehicle.TcoCalculations?.BevVehicle?.VehiclePrice === 0 ||
        vehicle.TcoCalculations?.BevVehicle?.VehiclePrice <= vehicle.TcoCalculations?.BevVehicle?.ResidualValue ||
        (vehicle.TcoCalculations?.ComparisonVehicle &&
          vehicle.TcoCalculations?.ComparisonVehicle?.VehiclePrice <=
            vehicle.TcoCalculations?.ComparisonVehicle?.ResidualValue),
    },
  );

  const fromHeader = useMemo(
    () =>
      presentEvaluation && vehicle.TcoCalculations?.ComparisonVehicle
        ? vehicleTypeOptions.find((x) => x.value === vehicle.TcoCalculations!.ComparisonVehicle!.EngineType)!.name
        : '...',
    [presentEvaluation, vehicle],
  );

  const chartData = useFinancialEvaluationChartData(vehicle, vehicleCalculation.data?.Content);

  return (
    <div className="px-spacing-16 py-spacing-16">
      <FinancialEvaluationVisual
        fromHeader={fromHeader}
        isPresentation
        data={chartData}
        currency={presentEvaluation ? vehicle.TcoCalculations?.Currency : ''}
        vehicleCalculation={vehicleCalculation.data?.Content}
      />
    </div>
  );
};
