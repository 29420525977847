import { FactBasicBus } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicBusses?: FactBasicBus[];
}

const useBodyLengths = ({ basicBusses }: Props) =>
  useMemo(
    () => (basicBusses ? [...new Set(basicBusses.map((basicBus) => basicBus.BodyLengthM).flat())].sort() : []),
    [basicBusses],
  );

export default useBodyLengths;
