import classNames from 'classnames';
import { APP_TITLE } from 'app/config';

interface Props {
  className?: string;
}

const AppInfo = ({ className }: Props) => (
  <p className={classNames('tds-body-01', className)}>
    <strong>{APP_TITLE}</strong> has facilitated a tailored analysis, guiding the transition to an electrified fleet.
    Comprehensive definitions of future, electrified vehicles and depot charging infrastructure have been carefully
    outlined. This solution has evolved into a tangible electrification plan, complete with detailed, yearly performance
    steps
  </p>
);

export default AppInfo;
