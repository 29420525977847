import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  solutionId: string;
  performanceSteps: SolutionPerformanceStep[];
}

const useNavigateToYearByTabIndex = ({ solutionId, performanceSteps }: Props) => {
  const navigate = useNavigate();

  return useCallback(
    (tabIndex: number) => {
      const performanceStep = performanceSteps[tabIndex];

      if (performanceStep) {
        navigate(`/solution/${solutionId}/presentation/performance-steps/${performanceStep.year}`);
      }
    },
    [solutionId, performanceSteps, navigate],
  );
};

export default useNavigateToYearByTabIndex;
