import Card from 'common/components/Card';
import classes from './style.module.scss';
import classNames from 'classnames';
import { chassisAdaptationPresentation } from '@optimization/sa-common';
import { HomeChargingIcon } from '@optimization/ssi-common';
import { Link, useLocation } from 'react-router-dom';
import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { TdsDivider, TdsIcon } from '@scania/tegel-react';
import { useMemo } from 'react';

interface Props {
  marginTop: number;
  performanceStep: SolutionPerformanceStep;
  solutionId?: string;
  className?: string;
}

const SolutionPerformanceStepTeaser = ({ marginTop, performanceStep, solutionId, className }: Props) => {
  const location = useLocation();

  const { year, depots } = performanceStep;

  const yearPresentation = useMemo(() => year.toString().substring(2), [year]);

  const chassisTypeAndAdaptations = useMemo(
    () => Object.keys(performanceStep.vehiclesCountByChassisTypeAndAdaptation),
    [performanceStep],
  );

  return (
    <Card
      className={classNames(classes['solution-performance-step-teaser'], className)}
      paddingClassName=""
      fullHeight
      style={{ marginTop: `${marginTop}px` }}
    >
      <Link
        className="h-full flex flex-col justify-between"
        to={`/solution/${solutionId}/presentation/performance-steps/${performanceStep.year}${location.search}`}
        data-testid="link-solution-performance-step"
      >
        <h3 className={classNames('tds-expressive-headline-01', 'tds-text-blue-800', classes.year)}>
          {yearPresentation}
        </h3>
        <div className="">
          <div className="mt-spacing-24">
            <TdsIcon name="truck" size="32" />
            <p className="tds-text-grey-846 tds-body-01 mt-spacing-4">Vehicles</p>
          </div>
          <div className={classNames('mt-spacing-8', classes.vehicles)}>
            <table>
              <tbody>
                {chassisTypeAndAdaptations.map((chassisTypeAndAdaptation) => (
                  <tr className="tds-detail-02" key={chassisTypeAndAdaptation}>
                    <td className="tds-text-grey-846">
                      {performanceStep.vehiclesCountByChassisTypeAndAdaptation[chassisTypeAndAdaptation]}
                      x&nbsp;
                    </td>
                    <td>{chassisAdaptationPresentation[chassisTypeAndAdaptation] || 'Bus'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {depots && (
            <>
              <div className="tds-u-mt2 tds-u-mb2">
                <TdsDivider />
              </div>
              <div>
                <div className={classes.depots}>
                  <div>
                    <HomeChargingIcon size="lg" color="gray" />
                    <p className="tds-text-grey-846 tds-body-01 mt-spacing-4">Depots</p>
                  </div>
                  {depots.map((depot, i) => (
                    <div key={depot.Id} className={i === 0 ? 'tds-u-mt1' : 'mt-spacing-16'}>
                      <p className="tds-detail-02 mb-spacing-4">{depot.Name}</p>
                      <div className="tds-detail-05">
                        <span className="tds-text-grey-846">Chargers:</span> {depot.chargersCount}
                      </div>
                      <div className="tds-detail-05">
                        <span className="tds-text-grey-846">Power demand:</span> {depot.peakChargingPowerDemand} kW
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Link>
    </Card>
  );
};

export default SolutionPerformanceStepTeaser;
