import { useEffect } from 'react';
import { useGetDepotQuery } from 'app/services/solution';
import { getEnhancedDepot, DepotEnhanced, ListVehicleTransformed } from '@optimization/sa-common';

export interface EnhancedDepotQuery {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  data?: DepotEnhanced;
}

export interface KeyedEnhancedDepotQueries {
  [key: string]: EnhancedDepotQuery;
}

export type SetEnhancedDepot = (depotId: number, depot: EnhancedDepotQuery | null) => void;

interface Props {
  solutionId: string;
  depotId: number;
  depotYear?: string;
  vehiclesInSolution?: ListVehicleTransformed[];
  setEnhancedDepot: SetEnhancedDepot;
}

// Becasue useLazyQuery might not work correctly with client caching
const GetEnhancedDepot = ({ solutionId, depotId, depotYear, vehiclesInSolution, setEnhancedDepot }: Props) => {
  const depotQuery = useGetDepotQuery({
    solutionId,
    depotId: depotId.toString(),
  });

  useEffect(() => {
    setEnhancedDepot(depotId, {
      data: depotQuery.data
        ? getEnhancedDepot({
            depot: depotQuery.data,
            depotYear,
            vehiclesInSolution,
          })
        : undefined,
      isLoading: depotQuery.isLoading,
      isError: depotQuery.isError,
      isSuccess: depotQuery.isSuccess,
    });
  }, [depotYear, depotId, depotQuery, vehiclesInSolution, setEnhancedDepot]);

  useEffect(
    () => () => {
      setEnhancedDepot(depotId, null);
    },
    [depotId, setEnhancedDepot],
  );

  return null;
};

export default GetEnhancedDepot;
