import { ListVehicleTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useNextGenVehicles = (vehicles: ListVehicleTransformed[] | undefined) =>
  useMemo(() => {
    const nextGen = vehicles?.filter((vehicle) => !vehicle.CompositeId && vehicle.ChassisType === 'Truck');

    const trailerNextGenVehicles = nextGen?.filter((vehicle) => vehicle.ChassisAdaptation === 'A');
    const rigidNextGenVehicles = nextGen?.filter((vehicle) => vehicle.ChassisAdaptation === 'B');

    return {
      trailerNextGenVehicles: trailerNextGenVehicles || [],
      rigidNextGenVehicles: rigidNextGenVehicles || [],
    };
  }, [vehicles]);

export default useNextGenVehicles;
