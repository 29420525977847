import classes from './style.module.scss';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { InlineTabs, TInlineTabs } from '@optimization/ssi-common';

interface Props {
  className?: string;
  children: React.ReactNode;
  tabs?: TInlineTabs<string | number>;
  tabIndex?: number;
  tabsRef?: React.MutableRefObject<HTMLDivElement | null>;
  solutionNamePresentation?: string;
  setTabIndex?: (tabIndex: number) => void;
}

const PageHeaderHome = ({
  className,
  children,
  tabs,
  tabIndex,
  tabsRef,
  solutionNamePresentation,
  setTabIndex,
}: Props) => {
  const hasLongName = useMemo(
    () => Boolean(solutionNamePresentation && solutionNamePresentation.length > 25),
    [solutionNamePresentation],
  );

  return (
    <div className={classNames(classes['page-header-home'], { [classes['has-tabs']]: Boolean(tabs) }, className)}>
      <div className={classNames('tds-container', classes.inner)}>
        <div className={classes.header}>
          <div className="tds-headline-07">Electrified solution</div>
          <h1 className={classNames('tds-headline-01', 'mt-spacing-4', { [classes['has-long-name']]: hasLongName })}>
            {solutionNamePresentation}
          </h1>
        </div>
        <div className={classes.menu}>{children}</div>
      </div>
      {tabs && (
        <div className={classes['tabs-wrapper']} ref={tabsRef}>
          <div className="tds-container">
            <InlineTabs
              className={classes.tabs}
              tabs={tabs}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              modeVariant="secondary"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeaderHome;
