import classes from './style.module.scss';
import classNames from 'classnames';
import useCandidatesHandler from 'features/candidate/Candidates/utils/useCandidatesHandler';
import useExportSolutionHandler from './useExportSolutionHandler';
import { CandidateHandler } from 'features/candidate/Candidates/utils/useCandidatesHandler';
import { EnhancedDepotsHandler } from '@optimization/sa-common';
import { Error } from '@optimization/ssi-common';
import { IconNames } from '@scania/tegel';
import { Link, useLocation } from 'react-router-dom';
import { TdsIcon } from '@scania/tegel-react';
import { useMemo } from 'react';

interface Props {
  solutionId: string;
  getEnhancedDepotsHandler: EnhancedDepotsHandler;
  candidatesHandler: CandidateHandler;
}

const Menu = ({ solutionId }: Props) => {
  const location = useLocation();

  const candidatesHandler = useCandidatesHandler({
    solutionId,
    includeCandidateWithNonExecutedVehicleProduct: true,
  });

  const exportHandler = useExportSolutionHandler({
    solutionId,
    candidatesHandler,
  });

  const menuItems: { to: { pathname: string; search: string }; label: string; icon: IconNames }[] = useMemo(
    () => [
      {
        to: {
          pathname: `/solution/${solutionId}/presentation/product-showcase`,
          search: location.search,
        },
        label: 'Product showcase',
        icon: 'arrow_right',
      },
      {
        to: {
          pathname: `/solution/${solutionId}/presentation/performance-steps`,
          search: location.search,
        },
        label: 'Performance steps',
        icon: 'arrow_right',
      },
    ],
    [solutionId, location.search],
  );

  return (
    <ul className={classes.menu}>
      <li>
        <button
          type="button"
          className="cursor-pointer tds-paragraph-02"
          onClick={exportHandler.runExport}
          disabled={exportHandler.disabled}
        >
          <span className="tds-text-grey-700">Export presentation</span>
          <span className="tds-text-grey-700">
            <TdsIcon name="export" size="20px" />
          </span>
        </button>
        {exportHandler.isError && <Error />}
      </li>
      {menuItems.map(({ to, label, icon }, index) => (
        <li key={index}>
          <Link className={classNames(classes['menu-item'], 'tds-paragraph-02')} to={to}>
            <span>{label}</span>
            {icon && (
              <span>
                <TdsIcon name={icon} size="20px" />
              </span>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Menu;
