import classes from './style.module.scss';
import React, { ReactNode } from 'react';

interface Props {
  intro?: string;
  children: React.ReactNode;
  description?: ReactNode[];
  dataTestid?: string;
  className?: string;
}

const PageHeader = ({ intro, children, description, dataTestid, className }: Props) => (
  <div className={className} data-testid={dataTestid}>
    {intro && <h2 className="tds-body-02 uppercase tds-u-mb1">{intro}</h2>}
    <h1 className={classes['page-header']}>{children}</h1>
    {description?.map((p, index) => (
      <p key={index} className="tds-body-02 tds-u-mt2">
        {p}
      </p>
    ))}
  </div>
);

export default PageHeader;
