import { FactBasicBus, FactBasicTruck, getBusWheelConfigurationValue } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles?: (FactBasicTruck | FactBasicBus)[];
}

const useBusWheelConfigurations = ({ basicVehicles }: Props) =>
  useMemo(
    () =>
      basicVehicles
        ? [
            ...new Set(
              basicVehicles
                .map((basicVehicle) =>
                  getBusWheelConfigurationValue({
                    chassisAdaptation: basicVehicle.ChassisAdaptation,
                    wheelConfiguration: basicVehicle.WheelConfiguration,
                  }),
                )
                .flat(),
            ),
          ].sort()
        : [],
    [basicVehicles],
  );

export default useBusWheelConfigurations;
