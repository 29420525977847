import { FactBasicTruck } from '@optimization/sa-common';
import { getLaunchPeriodPresentationAlt } from '@optimization/sa-common';
import { useMemo } from 'react';

interface UseFilteredTrucksProps {
  basicTrucks: FactBasicTruck[];
  launchPeriodPresentationAlt: string;
  statusTabIndex: number;
  chassisType: string;
}

const useFilteredTrucks = ({
  basicTrucks,
  launchPeriodPresentationAlt,
  statusTabIndex,
  chassisType,
}: UseFilteredTrucksProps) => {
  const trucksInLaunchPeriod = useMemo(
    () =>
      (chassisType === 'Truck' &&
        basicTrucks.filter(
          (basicTruck) => getLaunchPeriodPresentationAlt(basicTruck.Period) === launchPeriodPresentationAlt,
        )) ||
      [],
    [chassisType, basicTrucks, launchPeriodPresentationAlt],
  );

  const truckStatus = useMemo(
    () => [...new Set(trucksInLaunchPeriod.map((basicTruck) => basicTruck.Status).reverse())],
    [trucksInLaunchPeriod],
  );

  const trucksInStatus = useMemo(
    () => trucksInLaunchPeriod.filter((basicTruck) => basicTruck.Status === truckStatus[statusTabIndex]) || [],
    [trucksInLaunchPeriod, truckStatus, statusTabIndex],
  );

  const articulatedTrucks = useMemo(
    () => trucksInStatus.filter((basicTruck) => basicTruck.ChassisAdaptation === 'A'),
    [trucksInStatus],
  );

  const rigidTrucks = useMemo(
    () => trucksInStatus.filter((basicTruck) => basicTruck.ChassisAdaptation === 'B'),
    [trucksInStatus],
  );

  return {
    truckStatus,
    articulatedTrucks,
    rigidTrucks,
  };
};

export default useFilteredTrucks;
