import useChargerVariantValues from 'features/infrastructure/utils/useChargerVariantValues';
import useComplements from './useComplements';
import { KeyedNumber } from '@optimization/ssi-common';
import { useMemo } from 'react';
import {
  SolutionPerformanceStep,
  SolutionPerformanceStepCharger,
} from 'features/presentation/PerformanceSteps/Steps/utils/types';
import {
  getInfrastructureProducts,
  useEnhancedListVehicles,
  EnhancedDepotsHandler,
  ListDepotTransformed,
  FactBasicDataResponse,
  ListVehicleTransformed,
} from '@optimization/sa-common';

const getChargerKey = (charger: SolutionPerformanceStepCharger) =>
  `${charger.solution}-${charger.brand}-${charger.power}-${charger.satellites}`;

interface Props {
  factBasicDataResponse?: FactBasicDataResponse;
  vehiclesInSolution?: ListVehicleTransformed[];
  depotsInSolution?: ListDepotTransformed[];
  getEnhancedDepotsHandler: EnhancedDepotsHandler;
}

const useSolutionPerformanceSteps = ({
  factBasicDataResponse,
  vehiclesInSolution,
  depotsInSolution,
  getEnhancedDepotsHandler,
}: Props) => {
  const variantValues = useChargerVariantValues(factBasicDataResponse?.ChargingSolutions);

  const enhancedVehiclesInSolution = useEnhancedListVehicles({
    vehicles: vehiclesInSolution,
    factBasicDataQueryData: factBasicDataResponse,
  });

  const { years, vehicles, depots, vehiclesCountByChassisTypeAndAdaptation } = useComplements({
    vehiclesInSolution: enhancedVehiclesInSolution,
    depotsInSolution,
    getEnhancedDepotsHandler,
  });

  return useMemo(() => {
    let performanceSteps: SolutionPerformanceStep[] = [];

    for (const year of years) {
      performanceSteps.push({
        year,
        vehicles: vehicles[year],
        depots: depots?.[year]?.map((depot) => ({
          ...depot,
          chargers: getInfrastructureProducts({
            depotYear: year.toString(),
            infrastructureProducts: depot.InfrastructureProducts,
          })
            .filter((infrastructureProduct) =>
              infrastructureProduct.RequiredAt ? parseInt(infrastructureProduct.RequiredAt) <= year : false,
            )
            .map((infrastructureProduct) => ({
              brand: (variantValues[infrastructureProduct.Brand || ''] as string) || '',
              solution: (variantValues[infrastructureProduct.Solution || ''] as string) || '',
              power: (variantValues[infrastructureProduct.Power || ''] as number) || 0,
              satellites: (variantValues[infrastructureProduct.Outlets || ''] as number) || 0,
              year: infrastructureProduct.RequiredAt ? parseInt(infrastructureProduct.RequiredAt) : 0,
            })),
        })),
        vehiclesCountByChassisTypeAndAdaptation: vehiclesCountByChassisTypeAndAdaptation[year],
      });
    }

    for (let i = 0; i < performanceSteps.length; i++) {
      const performanceStepCurrent = performanceSteps[i];

      if (performanceStepCurrent.depots) {
        for (const depot of performanceStepCurrent.depots) {
          depot.peakChargingPowerDemand =
            depot.DepotDataPerPerformanceStep?.[performanceStepCurrent.year.toString()]?.PowerDemand || 0;

          const countChargersPerGroup: KeyedNumber = depot.chargers.reduce<KeyedNumber>((acc, charger) => {
            const chargerKey = getChargerKey(charger);
            return {
              ...acc,
              [chargerKey]: acc[chargerKey] ? acc[chargerKey] + 1 : 1,
            };
          }, {});

          depot.groupedChargers = Object.keys(countChargersPerGroup).map((chargerKey) => {
            const charger = depot.chargers.find((charger) => getChargerKey(charger) === chargerKey);

            return {
              solution: '',
              brand: '',
              power: 0,
              satellites: 0,
              year: 0,
              count: countChargersPerGroup[chargerKey],
              ...charger,
            };
          });
        }
      }
    }

    return performanceSteps;
  }, [years, variantValues, vehicles, depots, vehiclesCountByChassisTypeAndAdaptation]);
};

export default useSolutionPerformanceSteps;
