import { ShowcaseLaunchPeriod } from './useShowcaseLaunchPeriods';
import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface LaunchPeriodTabsProps {
  showcaseLaunchPeriods: ShowcaseLaunchPeriod[];
  hasBusses: boolean;
  hasTrucks: boolean;
}

const useLaunchPeriodTabs = ({
  showcaseLaunchPeriods,
  hasBusses,
  hasTrucks,
}: LaunchPeriodTabsProps): TInlineTabs<string> => {
  const launchPeriodTabs: TInlineTabs<string> = useMemo(
    () =>
      showcaseLaunchPeriods.map((showcaseLaunchPeriod) => {
        let name = showcaseLaunchPeriod.launchPeriodPresentationAlt;

        if (hasBusses && hasTrucks) {
          name = `${showcaseLaunchPeriod.chassisType} / ${showcaseLaunchPeriod.launchPeriodPresentationAlt}`;
        }

        return { name };
      }),
    [showcaseLaunchPeriods, hasBusses, hasTrucks],
  );

  return launchPeriodTabs;
};

export default useLaunchPeriodTabs;
