import { FactBasicTruck } from '@optimization/sa-common';
import { roundTo } from '@optimization/ssi-common';
import { useMemo } from 'react';

interface Props {
  basicTrucks?: FactBasicTruck[];
}

const useMaxGtws = ({ basicTrucks }: Props) =>
  useMemo(
    () =>
      basicTrucks
        ? [
            ...new Set(
              basicTrucks.map((basicTruck) => roundTo(basicTruck.TechnicalMaxGtwKg / 1000, 0)).filter((item) => item),
            ),
          ].sort()
        : [],
    [basicTrucks],
  );

export default useMaxGtws;
