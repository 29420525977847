import classes from './style.module.scss';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { BackLink } from '@optimization/sa-common';
import { InlineTabs, TInlineTabs } from '@optimization/ssi-common';

interface Props {
  className?: string;
  children: React.ReactNode;
  backLinkText?: string;
  backLinkUrl?: string;
  description?: ReactNode[];
  tabs?: TInlineTabs<string | number>;
  tabIndex?: number;
  tabsRef?: React.MutableRefObject<HTMLDivElement | null>;
  setTabIndex?: (tabIndex: number) => void;
}

const PageHeaderAlt = ({
  className,
  children,
  backLinkText,
  backLinkUrl,
  description,
  tabs,
  tabIndex,
  tabsRef,
  setTabIndex,
}: Props) => (
  <div className={classNames(classes['page-header-alt'], { [classes['has-tabs']]: Boolean(tabs) }, className)}>
    <div className="tds-container">
      {backLinkText && backLinkUrl && (
        <BackLink url={backLinkUrl} className="mb-spacing-24">
          {backLinkText}
        </BackLink>
      )}
      <div className={classes.body}>
        <h1 className="tds-headline-04">{children}</h1>
        {description?.map((p, index) => (
          <p key={index} className="tds-body-02 tds-u-mt1">
            {p}
          </p>
        ))}
      </div>
    </div>
    {tabs && (
      <div className={classes['tabs-wrapper']} ref={tabsRef}>
        <div className="tds-container">
          <InlineTabs
            className={classes.tabs}
            tabs={tabs}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            modeVariant="secondary"
          />
        </div>
      </div>
    )}
  </div>
);

export default PageHeaderAlt;
