import CandidateGraph from './CandidateGraph';
import classes from './style.module.scss';
import classNames from 'classnames';
import DepartureAndSlowChargeFields from './DepartureAndSlowChargeFields';
import useDepotNamesById from './utils/useDepotNamesById';
import useLoadingText from 'common/hooks/useLoadingText';
import { ContentSwitcherButtons, Loading } from '@optimization/ssi-common';
import { useCallback, useMemo } from 'react';
import { useGetDepotQuery, useGetDepotsQuery } from 'app/services/solution';
import {
  useCandidateChargingEvents,
  useCandidateChargingDistance,
  useGetCandidateChargingTime,
  useMedianTick,
  useMedianTickIsOver,
  VehicleEnhanced,
  ChargingEventsList,
  DEPARTURE_TIME_DEFAULT,
} from '@optimization/sa-common';

interface Props {
  vehicle: VehicleEnhanced;
  solutionId: string;
  baseRange: number;
  enableYearSelectorForChargingEvents: boolean;
  setDepotYear: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CandidateCharging = ({
  vehicle,
  solutionId,
  baseRange,
  enableYearSelectorForChargingEvents,
  setDepotYear,
}: Props) => {
  const nowTime = useMemo(() => new Date().getTime(), []);

  const dailyOdometerMedianKm = vehicle.ExecutedVehicleProduct?.DailyOdometerMedianKm;

  const dailyOdometerLongestKm = vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm || 0;

  const usableEnergy = vehicle.ExecutedVehicleProduct?.Soc?.UsableEnergyKWh || 0;

  const depotQuery = useGetDepotQuery(
    {
      solutionId,
      depotId: vehicle.HomeDepotId?.toString() || '',
    },
    { skip: !Boolean(vehicle.HomeDepotId) },
  );

  const depotsQuery = useGetDepotsQuery(solutionId);

  const depotNames = useDepotNamesById({ depots: depotsQuery.data });

  const { chartChargingEvents, savedChartChargingEvents } = useCandidateChargingEvents({
    chargingEvents: vehicle.enhanced.vehiclePerformanceStep?.ChargingEvents,
  });

  const { distanceIncludingPreview, distanceFromSavedChargingEvents } = useCandidateChargingDistance({
    chartChargingEvents,
    savedChartChargingEvents,
    dailyOdometerLongestKm,
    totEc: vehicle.enhanced.totEc,
    usableEnergy,
    nowTime,
    slowChargingEvent: vehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent,
    departureTime: vehicle.enhanced.vehiclePerformanceStep?.DepartureTime,
  });

  const getCandidateChargingTime = useGetCandidateChargingTime({
    distanceFromSavedChargingEvents,
    distanceIncludingPreview,
  });

  const medianTick = useMedianTick({ dailyOdometerLongestKm, dailyOdometerMedianKm });
  const medianTickIsOver = useMedianTickIsOver({ medianTick });

  const departureTime = vehicle.enhanced.vehiclePerformanceStep?.DepartureTime || DEPARTURE_TIME_DEFAULT;

  const slowChargeStartTime = vehicle.enhanced.vehiclePerformanceStep?.SlowChargingEvent?.TimeAtEvent || '';

  const findChargingEventKmOffset = useMemo(() => dailyOdometerLongestKm / 58, [dailyOdometerLongestKm]);

  const findChargingEvent = useCallback(
    (primaryValue: number) =>
      vehicle.enhanced.vehiclePerformanceStep?.ChargingEvents.find(
        (chargingEvent) =>
          chargingEvent.KmAtEvent >= primaryValue - findChargingEventKmOffset &&
          chargingEvent.KmAtEvent <= primaryValue + findChargingEventKmOffset,
      ),
    [vehicle, findChargingEventKmOffset],
  );

  const years = useMemo(
    () =>
      vehicle.VehiclePerformanceSteps.map((item) => item.PerformanceStep || '').filter(
        (performanceStep) => performanceStep,
      ),
    [vehicle],
  );

  const contentSwitcherItems = useMemo(
    () =>
      enableYearSelectorForChargingEvents
        ? years.map((year) => ({
            id: year,
            name: year,
            active: vehicle.enhanced.vehicleDepotYear === year,
            onClick: () => {
              setDepotYear(year);
            },
          }))
        : [],
    [years, vehicle, enableYearSelectorForChargingEvents, setDepotYear],
  );

  const isLoading = depotsQuery.isLoading || depotQuery.isLoading;
  const isError = depotsQuery.isError || depotQuery.isError;

  const depot = depotQuery.data;
  const depots = depotsQuery.data;

  const loadingText = useLoadingText({
    isLoadingDepot: isLoading,
  });

  if (!vehicle.enhanced.vehiclePerformanceStep || !depot || !depots) {
    return null;
  }

  return (
    <>
      <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />
      <div className={classNames(classes['candidate-charging'], 'bg-grey-50')}>
        <div className={classes.top}>
          <div>
            <div className={classes.header}>
              <DepartureAndSlowChargeFields departureTime={departureTime} slowChargeStartTime={slowChargeStartTime} />
              {contentSwitcherItems.length > 1 && (
                <div className={classes['content-switcher']}>
                  <ContentSwitcherButtons items={contentSwitcherItems} />
                </div>
              )}
            </div>
            <CandidateGraph
              chartChargingEvents={chartChargingEvents}
              distanceIncludingPreview={distanceIncludingPreview}
              vehicle={vehicle}
              dailyRange={baseRange}
              departureTime={departureTime}
              dailyOdometerMedianKm={dailyOdometerMedianKm}
              dailyOdometerLongestKm={dailyOdometerLongestKm}
              medianTick={medianTick}
              medianTickIsOver={medianTickIsOver}
              getCandidateChargingTime={getCandidateChargingTime}
              findChargingEvent={findChargingEvent}
            />
          </div>
          <ChargingEventsList
            className={classes['charging-events-list']}
            departureTime={departureTime}
            depotName={depot.Name}
            depotNames={depotNames}
            isPresentation
            chargingEvents={vehicle.enhanced.vehiclePerformanceStep.ChargingEvents}
            energyConsumptionPerDayKwh={vehicle.enhanced.vehiclePerformanceStep.EnergyConsumptionPerDayKwh}
            slowChargingEvent={vehicle.enhanced.vehiclePerformanceStep.SlowChargingEvent}
          />
        </div>
      </div>
    </>
  );
};

export default CandidateCharging;
