import { ListDepotTransformed } from '@optimization/sa-common';
import { useMemo } from 'react';

const useChargersCount = (depots: ListDepotTransformed[]) =>
  useMemo(
    () =>
      depots.reduce(
        (total, depot) => total + depot.InfrastructureProducts.reduce((sum, product) => sum + product.Quantity, 0),
        0,
      ),
    [depots],
  );

export default useChargersCount;
