import { SolutionPerformanceStep } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useMemo } from 'react';

interface Props {
  selectedPerformanceStep?: SolutionPerformanceStep;
  depotsCount?: number;
}

const useSplicedDepots = ({ selectedPerformanceStep, depotsCount }: Props) =>
  useMemo(
    () =>
      selectedPerformanceStep && selectedPerformanceStep.depots
        ? selectedPerformanceStep.depots.filter((_, index) => (depotsCount ? index < depotsCount : false))
        : null,
    [selectedPerformanceStep, depotsCount],
  );

export default useSplicedDepots;
