export const APP_TITLE = import.meta.env.VITE_APP_TITLE;
export const APP_USP = import.meta.env.VITE_APP_USP;

export const SITE_HEADER_HEIGHT = 64;

export const MULTIPLE_VALUES_SEPARATOR = ',';

export const MAX_CABLE_POWER_KW = 325;

export const TYPE_DESIGNATION_MODULAR = '';

export const SUPPORT_NAME = 'Jakob Petersén';
export const SUPPORT_EMAIL = 'jakob.petersen@scania.com';

export const MODE = import.meta.env.MODE;

export const MAPBOX_ENABLED = import.meta.env.VITE_MAP_BOX_ENABLED === 'true';

export const MOCKING_ENABLED = import.meta.env.VITE_MOCKING_ENABLED === 'true';

export const SOLUTION_ENDPOINT = import.meta.env.VITE_SOLUTION_ENDPOINT;

export const LOCALE_DATE = 'en-GB';

export const APP_IS_UPDATED_STORAGE_KEY = 'appIsUpdated';

export const MAPBOX_API_KEY = import.meta.env.VITE_MAPBOX_API_KEY;
