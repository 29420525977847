import { ListVehicleEnhanced } from '@optimization/sa-common';
import { useMemo } from 'react';

const useLaunchPeriods = (vehicles?: ListVehicleEnhanced[]): string[] =>
  useMemo(() => {
    if (!vehicles) {
      return [];
    }

    const launchPeriods = [...new Set(vehicles.map((vehicle) => vehicle.transformed.launchPeriodPresentation))].sort();

    return launchPeriods;
  }, [vehicles]);

export default useLaunchPeriods;
