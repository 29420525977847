import Card from 'common/components/Card';
import classes from './style.module.scss';
import { ListVehicleTransformed } from '@optimization/sa-common';

interface Props {
  headline: string;
  vehicles: ListVehicleTransformed[];
}

const SimpleTeaser = ({ headline, vehicles }: Props) => (
  <Card className={classes['simple-teaser']}>
    <p className="tds-detail-02 mb-spacing-32">{headline}</p>
    <p className="tds-expressive-headline-01 tds-text-blue-800">{vehicles.length}</p>
  </Card>
);

export default SimpleTeaser;
