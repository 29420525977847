import HasSignedTos from 'features/auth/HasSignedTos';
import Header from 'features/navigation/Header';
import Login from 'features/auth/Login';
import PageAccessDenied from 'common/components/PageAccessDenied';
import SolutionExists from 'features/solution/SolutionExists';
import { useAppSelector } from 'app/hooks';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const accessDenied = useAppSelector((state) => state.auth.accessDenied);
  const accessToken = useAppSelector((state) => state.auth.accessToken);

  return (
    <>
      <Header />
      <main>
        {accessDenied ? (
          <PageAccessDenied className="mt-spacing-144" />
        ) : (
          <>
            <AuthenticatedTemplate>
              {accessToken && (
                <HasSignedTos>
                  <SolutionExists>{children}</SolutionExists>
                </HasSignedTos>
              )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </>
        )}
      </main>
    </>
  );
};

export default Layout;
