import classes from './tooltip.module.scss';
import classNames from 'classnames';
import { BatteriesIcon, formatMinutesAsHoursAndMinutes, LightningIcon, roundTo } from '@optimization/ssi-common';
import { ChargingEventV2VM, ChartTooltip, GetCandidateChargingTime, SAFETY_MARGIN_KM } from '@optimization/sa-common';
import { TdsIcon } from '@scania/tegel-react';
import { TooltipOptions } from 'react-charts';
import { TooltipRendererProps } from 'react-charts/types/components/TooltipRenderer';
import { useCallback, useMemo } from 'react';

interface Props {
  departureTime: string;
  findChargingEvent: (primaryValue: number) => ChargingEventV2VM | undefined;
  getCandidateChargingTime: GetCandidateChargingTime;
}

const useTooltip = ({ departureTime, findChargingEvent, getCandidateChargingTime }: Props) => {
  const addMinutesToTime = (time: string, minutesToAdd: number) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes + minutesToAdd, 0, 0);

    const newHours = date.getHours();
    const newMinutes = date.getMinutes();

    return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  };

  const getChargingEventTooltip = useCallback((chargingDuration: number, timeAtEvent: string) => {
    const timeAfterEvent = addMinutesToTime(timeAtEvent, chargingDuration);

    return (
      <ChartTooltip className={classes['charging-tooltip']}>
        <div className="mb-spacing-8 tds-detail-05 flex">
          <LightningIcon /> <span className="ml-spacing-8">Charging event duration</span>
        </div>
        <div className="tds-detail-02">{formatMinutesAsHoursAndMinutes(chargingDuration)}</div>
        <div className="tds-detail-05">
          {timeAtEvent} - {timeAfterEvent}
        </div>
      </ChartTooltip>
    );
  }, []);

  const tooltip: TooltipOptions<unknown> = useMemo(() => {
    return {
      groupingMode: 'single',
      render: (props: TooltipRendererProps<unknown>) => {
        if (props.secondaryAxis.id === '2') {
          return (
            <ChartTooltip className={classes['charging-tooltip']}>Safety margin {SAFETY_MARGIN_KM} km</ChartTooltip>
          );
        }

        const departureTimeArr = departureTime.split(':');

        if (!departureTimeArr.length) {
          return;
        }

        const chargingEvent = findChargingEvent(props.focusedDatum?.primaryValue);

        if (chargingEvent) {
          return getChargingEventTooltip(chargingEvent.ChargingDuration, chargingEvent.TimeAtEvent);
        }

        const time = getCandidateChargingTime({
          dataPoint: {
            primary: props.focusedDatum?.primaryValue,
            secondary: props.focusedDatum?.secondaryValue,
          },
          distanceType: 'including-preview',
        });

        return (
          <ChartTooltip className={classes['charging-tooltip']}>
            <div className="flex">
              <div className="flex items-center w-56">
                <TdsIcon name="clock" size="16px" />
                <span className="ml-spacing-8 tds-detail-05">{time}</span>
              </div>
              <div className="flex items-center ml-spacing-16">
                <BatteriesIcon />
                <span className="ml-spacing-8 tds-detail-05">{roundTo(props.focusedDatum?.secondaryValue, 0)}%</span>
              </div>
            </div>
            <div className={classNames(classes.value, 'mt-spacing-8', 'tds-detail-05')}>
              <div>Current distance:</div>
              <div>{roundTo(props.focusedDatum?.primaryValue, 0)} km</div>
            </div>
            <div className={classNames(classes.value, 'tds-detail-05')}>
              <div>Left of daily distance:</div>
              <div>
                {roundTo(
                  //@ts-ignore TODO: Fix this
                  props.primaryAxis.max - props.focusedDatum?.primaryValue,
                  0,
                )}{' '}
                km
              </div>
            </div>
          </ChartTooltip>
        );
      },
    };
  }, [departureTime, getCandidateChargingTime, findChargingEvent, getChargingEventTooltip]);

  return tooltip;
};

export default useTooltip;
