import classes from './style.module.scss';
import EnergyConsumption from './EnergyConsumption';
import PresentationList from './utils/PresentationList';
import PresentationListItem from './utils/PresentationList/PresentationListItem';
import { roundTo } from '@optimization/ssi-common';
import {
  getBusWheelConfigurationValue,
  chassisAdaptationTranslate,
  chassisHeightTranslate,
  trafficTranslate,
  topographyTranslate,
  PtoDevices,
  VehicleEnhanced,
} from '@optimization/sa-common';

interface Props {
  netEc: number;
  vehicle: VehicleEnhanced;
  ptoDevices: PtoDevices;
}

const VehicleInfo = ({ netEc, vehicle, ptoDevices }: Props) => (
  <div className={classes['vehicle-info']}>
    <div>
      <h5 className="tds-headline-05 tds-u-mb2">Vehicle configuration</h5>
      <PresentationList>
        {vehicle.ExecutedVehicleProduct.ChassisType === 'Truck' &&
          vehicle.ExecutedVehicleProduct?.ChassisAdaptation && (
            <PresentationListItem
              label="Chassis adaptation"
              value={chassisAdaptationTranslate[vehicle.ExecutedVehicleProduct.ChassisAdaptation]}
            />
          )}
        {vehicle.ExecutedVehicleProduct.ChassisType === 'Truck' &&
          vehicle.ExecutedVehicleProduct?.WheelConfiguration && (
            <PresentationListItem
              label="Wheel configuration"
              value={vehicle.ExecutedVehicleProduct.WheelConfiguration}
            />
          )}
        {vehicle.ExecutedVehicleProduct.ChassisType === 'Bus' && vehicle.ExecutedVehicleProduct?.WheelConfiguration && (
          <PresentationListItem
            label="Wheel configuration"
            value={getBusWheelConfigurationValue({
              chassisAdaptation: vehicle.ExecutedVehicleProduct.ChassisAdaptation,
              wheelConfiguration: vehicle.ExecutedVehicleProduct.WheelConfiguration,
            })}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.AxleDistanceMm && (
          <PresentationListItem
            label="Axle distance"
            value={vehicle.ExecutedVehicleProduct.AxleDistanceMm.toString()}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.ChassisHeight && (
          <PresentationListItem
            label="Chassis height"
            value={chassisHeightTranslate[vehicle.ExecutedVehicleProduct.ChassisHeight]}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.Cab && (
          <PresentationListItem label="Cab type" value={vehicle.ExecutedVehicleProduct.Cab} />
        )}
        {vehicle.ExecutedVehicleProduct?.BodyLengthM && (
          <PresentationListItem label="Body length" value={vehicle.ExecutedVehicleProduct.BodyLengthM} />
        )}
        {vehicle.ExecutedVehicleProduct?.BusFloorHeight && (
          <PresentationListItem label="Floor height" value={vehicle.ExecutedVehicleProduct.BusFloorHeight} />
        )}
        {vehicle.ExecutedVehicleProduct?.ChargingSpecification?.ChargingType &&
          vehicle.ExecutedVehicleProduct?.ChargingSpecification?.ChargingPowerKW && (
            <PresentationListItem
              label="Charging type"
              value={`${vehicle.ExecutedVehicleProduct.ChargingSpecification.ChargingType} ${vehicle.ExecutedVehicleProduct.ChargingSpecification.ChargingPowerKW} kW`}
            />
          )}
      </PresentationList>
    </div>
    <div>
      <h5 className="tds-headline-05 tds-u-mb2">Operational factors</h5>
      <PresentationList>
        {vehicle.ExecutedVehicleProduct?.DailyOdometerMedianKm && (
          <PresentationListItem
            label="Daily distance, median day"
            value={`${vehicle.ExecutedVehicleProduct?.DailyOdometerMedianKm} km`}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm && (
          <PresentationListItem
            label="Daily distance, longest day"
            value={`${vehicle.ExecutedVehicleProduct?.DailyOdometerLongestKm} km`}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.AvgGtwKg && (
          <PresentationListItem
            label="Average GTW"
            value={`${roundTo(vehicle.ExecutedVehicleProduct?.AvgGtwKg / 1000, 0)} t`}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.AvgSpeedKmh && (
          <PresentationListItem label="Average speed" value={`${vehicle.ExecutedVehicleProduct?.AvgSpeedKmh} km/h`} />
        )}
        {vehicle.ExecutedVehicleProduct?.CruiseSpeedKmh && (
          <PresentationListItem
            label="Cruising speed"
            value={`${vehicle.ExecutedVehicleProduct?.CruiseSpeedKmh} km/h`}
          />
        )}
      </PresentationList>
    </div>
    <div>
      <h5 className="tds-headline-05 tds-u-mb2">User factors</h5>
      <PresentationList>
        {vehicle.ExecutedVehicleProduct?.Topography && (
          <PresentationListItem
            label="Topography"
            value={topographyTranslate[vehicle.ExecutedVehicleProduct?.Topography]}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.Traffic && (
          <PresentationListItem label="Traffic" value={trafficTranslate[vehicle.ExecutedVehicleProduct?.Traffic]} />
        )}
        {vehicle.ExecutedVehicleProduct?.TemperatureWinterC && (
          <PresentationListItem
            label="Min temperature"
            value={vehicle.ExecutedVehicleProduct?.TemperatureWinterC.toLocaleString()}
          />
        )}
        {vehicle.ExecutedVehicleProduct?.TemperatureSummerC && (
          <PresentationListItem
            label="Max temperature"
            value={
              vehicle.ExecutedVehicleProduct?.TemperatureSummerC
                ? `+${vehicle.ExecutedVehicleProduct?.TemperatureSummerC.toLocaleString()}`
                : vehicle.ExecutedVehicleProduct?.TemperatureSummerC.toLocaleString()
            }
          />
        )}
      </PresentationList>
    </div>
    <div>
      <h5 className="tds-headline-05 tds-u-mb2">Energy consumption</h5>
      <EnergyConsumption
        netEc={netEc}
        ptoDevices={ptoDevices}
        executedVehicleProductPTOs={vehicle.ExecutedVehicleProduct.ExecutedVehicleProductPTOs}
        chassisType={vehicle.ExecutedVehicleProduct?.ChassisType}
      />
    </div>
  </div>
);

export default VehicleInfo;
