import { CandidateTabs } from '../';
import { TInlineTabs } from '@optimization/ssi-common';
import { useMemo } from 'react';
import { VehicleEnhanced } from '@optimization/sa-common';
import { isUndefinedOrEmpty } from 'src/common/utils/helper';

interface Props {
  vehicle: VehicleEnhanced;
  showHistorialStops: boolean;
  chargingEventTabRef: React.RefObject<HTMLButtonElement>;
  historicalStopsTabRef: React.RefObject<HTMLButtonElement>;
}

const useTabs = ({ vehicle, showHistorialStops, chargingEventTabRef, historicalStopsTabRef }: Props) =>
  useMemo(() => {
    const inlineTabs: TInlineTabs<CandidateTabs> = [];

    inlineTabs.push({
      name: 'Candidate specs',
    });

    inlineTabs.push({
      name: 'Charging events',
      tabRef: chargingEventTabRef,
      disabled: !vehicle.HomeDepotId,
    });

    if (showHistorialStops) {
      inlineTabs.push({
        name: 'Historical stops',
        tabRef: historicalStopsTabRef,
      });
    }

    const isOldChassiVehicle = !!(
      vehicle.ScaniaChassis?.ChassisNumber && isUndefinedOrEmpty(vehicle.ScaniaChassis?.Consumption)
    );
    if (!isOldChassiVehicle) {
      inlineTabs.push({
        name: 'Environmental impact',
      });
    }

    if (vehicle.TcoCalculations && vehicle.TcoCalculations?.ShowInPresentationApp === true) {
      inlineTabs.push({
        name: 'Financial evaluation',
        dataTestid: 'button-financial-evaluation',
      });
    }

    return inlineTabs;
  }, [showHistorialStops, chargingEventTabRef, historicalStopsTabRef, vehicle]);

export default useTabs;
