import { Datetime } from '@optimization/ssi-common';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  value: string;
  label: string;
  requiredText?: string;
  onChange: (value: string) => void;
}

const DebouncedTimeField = ({ className, disabled, value: valueProp, label, requiredText, onChange }: Props) => {
  const [value, setValue] = useState(valueProp);
  const [changedByUser, setChangedByUser] = useState(false);
  const [changedByUserDone, setChangedByUserDone] = useState(false);

  const onChangeCallback = useCallback((timeString: string) => {
    setValue(timeString);
    setChangedByUser(true);
    setChangedByUserDone(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (changedByUser) {
        onChange(value);
        setChangedByUser(false);
      }
    }, 1500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [changedByUser, value, onChange]);

  useEffect(() => {
    setChangedByUser(false);
    setValue(valueProp);
  }, [valueProp]);

  return (
    <Datetime
      className={className}
      label={label}
      type="time"
      disabled={disabled}
      value={value}
      helper={changedByUserDone && !value ? requiredText : undefined}
      state={changedByUserDone && !value ? 'error' : 'none'}
      onChange={onChangeCallback}
    />
  );
};

export default DebouncedTimeField;
