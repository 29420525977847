import classes from './style.module.scss';
import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

interface Props {
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
  paddingClassName?: string;
  style?: CSSProperties;
  fullHeight?: boolean;
}

const Card = ({
  header,
  children,
  footer,
  className,
  paddingClassName = 'py-spacing-16 px-spacing-16',
  style,
  fullHeight,
}: Props) => (
  <article className={classNames(classes.card, paddingClassName, className)} style={style}>
    {header}
    <div
      className={classNames({
        'h-full': fullHeight,
      })}
    >
      {children}
    </div>
    {footer}
  </article>
);

export default Card;
