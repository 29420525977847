import classNames from 'classnames';

interface Props {
  label: string;
  value: string;
  className?: string;
  labelClassName?: string;
}

const PresentationListItem = ({ label, value, className, labelClassName }: Props) => (
  <li className={classNames('tds-text-grey-958', className)}>
    <div className={classNames('tds-detail-02', 'tds-text-grey-800', labelClassName)}>{label}</div> <div>{value}</div>
  </li>
);

export default PresentationListItem;
