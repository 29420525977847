import DepotInfoList from 'features/infrastructure/utils/DepotInfoList';
import ErrorBoundary from 'common/components/ErrorBoundary';
import HistoricalStops from 'features/candidate/HistoricalStops';
import TotalCount from 'common/components/TotalCount';
import useChassisNumbers from 'features/candidate/Candidates/utils/useChassisNumbers';
import { DepotChart, useDepotMaxSecondaryAxes } from '@optimization/sa-common';
import { HomeChargingIcon, InlineTabs, Modal, TInlineTabs } from '@optimization/ssi-common';
import { SolutionPerformanceStepDepot } from 'features/presentation/PerformanceSteps/Steps/utils/types';
import { useDepotChargingEventsChartData, useDepotPowerDemandChartData } from '@optimization/sa-common';
import { useState } from 'react';

type DepotTabs = 'Power demand' | 'Historical stops';

const tabs: TInlineTabs<DepotTabs> = [{ name: 'Power demand' }, { name: 'Historical stops' }];

interface Props {
  depot: SolutionPerformanceStepDepot;
  depotYear: string;
  solutionId: string;
  onClose: () => void;
}

const DepotModal = ({ depot, depotYear, solutionId, onClose }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const chargingEventsChartData = useDepotChargingEventsChartData({
    depot,
    depotYear,
  });

  const powerDemandChartData = useDepotPowerDemandChartData({
    depot,
    chargingEventsChartData,
  });

  const maxSecondaryAxes = useDepotMaxSecondaryAxes({
    depot,
    depotYear,
  });

  const chassisNumbers = useChassisNumbers(depot.vehicles);

  return (
    <Modal onClose={onClose} size="md">
      <div className="tds-u-ml1 tds-u-mr1 tds-u-mb1">
        <HomeChargingIcon size="lg" color="gray" />
        <div className="tds-u-mt2">
          <p className="tds-detail-05">Depot</p>
          <h3 className="tds-paragraph-02 mt-spacing-2">{depot.Name}</h3>
          <DepotInfoList className="max-w-420 mt-spacing-12" depot={depot} viewMode="detail" />
        </div>
        <InlineTabs className="tds-u-mt1" tabs={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        {tabs[tabIndex].name === 'Power demand' && (
          <DepotChart
            className="tds-u-mt3"
            variant="light"
            depotMaxPowerCapacity={depot.MaxPowerCapacity}
            dataPlot={powerDemandChartData}
            maxSecondaryAxes={maxSecondaryAxes}
            depotFutureMaxPowerCapacity={depot.FutureMaxPowerCapacity}
          />
        )}
        {tabs[tabIndex].name === 'Historical stops' && (
          <div className="tds-u-mt2">
            <ErrorBoundary>
              {chassisNumbers.length ? (
                <HistoricalStops
                  className="tds-u-mt2"
                  solutionId={solutionId}
                  enhancedVehicles={depot.vehicles}
                  mapHeight="28vh"
                />
              ) : (
                <TotalCount count={0} entityName="VEHICLE CANDIDATE" extraText="AVAILABLE FOR HISTORICAL STOPS" />
              )}
            </ErrorBoundary>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DepotModal;
