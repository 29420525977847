import CandidateCharging from './CandidateCharging';
import classes from './style.module.scss';
import classNames from 'classnames';
import EnvironmentalImpactWrapper from './EnvironmentalImpactWrapper';
import ErrorBoundary from 'common/components/ErrorBoundary';
import HistoricalStops from 'features/candidate/HistoricalStops';
import useDefaultTabInitially from './utils/useDefaultTabInitially';
import useResetDepotYear from './utils/useResetDepotYear';
import useTabs from './utils/useTabs';
import VehicleInfo from './VehicleInfo';
import { CandidateTabs } from '.';
import { FinancialEvaluation } from './FinancialEvaluation';
import { InlineTabs, Tooltip } from '@optimization/ssi-common';
import { MODE } from 'app/config';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetPtoDevicesQuery } from 'app/services/solution';
import {
  vehicleSettingsDefault,
  CandidateCreatedFrom,
  FactBasicDataResponse,
  RangeInfo,
  Season,
  toListVehicle,
  useEnhancedVehicle,
  VehicleTransformed,
  VehicleSettings,
  SetIsWinter,
  SetIsSoH80,
  SetShowTopUp,
  NET_EC_PARAM_DEFAULT,
} from '@optimization/sa-common';

interface Props {
  className?: string;
  candidateTabDefault?: CandidateTabs;
  enableYearSelectorForChargingEvents: boolean;
  solutionId: string;
  tooltipKey: number;
  vehicle: VehicleTransformed;
  factBasicDataQueryData: FactBasicDataResponse;
  vehicleSettings: VehicleSettings;
  setIsWinter: SetIsWinter;
  setIsSoH80: SetIsSoH80;
  setShowTopUp: SetShowTopUp;
}

const Main = ({
  className,
  candidateTabDefault = 'Candidate specs',
  enableYearSelectorForChargingEvents,
  solutionId,
  tooltipKey,
  vehicle,
  factBasicDataQueryData,
  vehicleSettings,
  setIsWinter,
  setIsSoH80,
  setShowTopUp,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const getPtoDevicesQuery = useGetPtoDevicesQuery();

  const [depotYear, setDepotYear] = useState<string | undefined>(vehicle.ExecutedVehicleProduct.PerformanceStep);

  const [tabIndex, setTabIndex] = useState(0);

  const season: Season = vehicleSettings.isWinter ? 'winter' : 'summer';

  const enhancedVehicle = useEnhancedVehicle({
    vehicle,
    vehicleSettings,
    depotYear,
    factBasicDataQueryData: factBasicDataQueryData,
  });

  const enhancedListVehicles = useMemo(() => [toListVehicle(enhancedVehicle)], [enhancedVehicle]);

  const batteryLife = enhancedVehicle.ExecutedVehicleProduct.EightyPercentSoH ?? 0;

  const chargingEventTabRef = useRef<HTMLButtonElement>(null);
  const historicalStopsTabRef = useRef<HTMLButtonElement>(null);
  const chassisNumber = enhancedVehicle.ScaniaChassis?.ChassisNumber || 0;

  const mapLocation = useMemo(
    () => enhancedVehicle.MapLocationsV2?.filter((item) => item.Name === 'vehicle-candidate')?.[0],
    [enhancedVehicle],
  );

  const showHistorialStops = useMemo(() => Boolean(chassisNumber), [chassisNumber]);

  const historialStopsDisabled = useMemo(() => showHistorialStops && !mapLocation, [showHistorialStops, mapLocation]);

  const tabs = useTabs({
    showHistorialStops,
    chargingEventTabRef,
    historicalStopsTabRef,
    vehicle: enhancedVehicle,
  });

  useDefaultTabInitially({
    tabs,
    vehicleId: enhancedVehicle.Id,
    candidateTabDefault,
    setTabIndex,
  });

  useResetDepotYear({
    tabs,
    tabIndex,
    vehicle: enhancedVehicle,
    enableYearSelectorForChargingEvents,
    setDepotYear,
  });

  const setIsWinterCallback = useCallback(
    (isWinter: boolean) => {
      setIsWinter({ vehicleId: vehicle.Id, isWinter });
    },
    [vehicle.Id, setIsWinter],
  );

  const setIsSoH80Callback = useCallback(
    (isSoH80: boolean) => {
      setIsSoH80({ vehicleId: vehicle.Id, isSoH80 });
    },
    [vehicle.Id, setIsSoH80],
  );

  const setShowTopUpCallback = useCallback(
    (showTopUp: boolean) => {
      setShowTopUp({ vehicleId: vehicle.Id, showTopUp });
    },
    [vehicle.Id, setShowTopUp],
  );

  useEffect(() => {
    if (tabIndex) {
      setIsWinterCallback(vehicleSettingsDefault.isWinter);
      setIsSoH80Callback(vehicleSettingsDefault.isSoH80);
      setShowTopUpCallback(vehicleSettingsDefault.showTopUp);
    }
  }, [tabIndex, setIsWinterCallback, setIsSoH80Callback, setShowTopUpCallback]);

  const netEc = enhancedVehicle.transformed.netEc[NET_EC_PARAM_DEFAULT][season];

  const cycledEnergyKWhKm = enhancedVehicle.transformed.cycledEnergy[NET_EC_PARAM_DEFAULT][season];

  return (
    <div className={classNames(classes.main, className)} data-testid="candidate-detail-container" ref={ref}>
      <InlineTabs
        tabs={tabs}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        breakpoint="lg"
        extra={
          <CandidateCreatedFrom
            key={tooltipKey}
            mode={MODE}
            vehicleId={enhancedVehicle.Id}
            chassisType={enhancedVehicle.ScaniaChassis?.ChassisType}
            chassisNumber={enhancedVehicle.ScaniaChassis?.ChassisNumber}
            assemblyDateStr={enhancedVehicle.ScaniaChassis?.AssemblyDate}
            avgGtwPresentation={enhancedVehicle.transformed.avgGtwPresentation}
            chassisAdaptation={enhancedVehicle.ScaniaChassis?.ChassisAdaptation}
            axleDistanceMm={enhancedVehicle.ScaniaChassis?.AxleDistanceMm}
            chassisHeight={enhancedVehicle.ScaniaChassis?.ChassisHeight}
            cab={enhancedVehicle.ScaniaChassis?.Cab}
            chassisIdPresentationAlt={enhancedVehicle.transformed.chassisIdPresentationAlt}
            wheelConfiguration={enhancedVehicle.ScaniaChassis?.WheelConfiguration}
            bodyLength={enhancedVehicle.ScaniaChassis?.BodyLengthM}
            floorHeight={enhancedVehicle.ScaniaChassis?.BusFloorHeight}
          />
        }
        extraClassName="inline-tabs-created-from"
      />
      {!enhancedVehicle.HomeDepotId && (
        <Tooltip elRef={chargingEventTabRef} key={tooltipKey}>
          Please map the candidate to a home depot in order to define charging events.
        </Tooltip>
      )}
      {historialStopsDisabled && (
        <Tooltip elRef={historicalStopsTabRef} key={tooltipKey}>
          To view chassis' historical stops, please use the stop placement feature for this vehicle candidate.
        </Tooltip>
      )}
      {tabs[tabIndex].name === 'Candidate specs' && (
        <ErrorBoundary>
          <RangeInfo
            className="mt-spacing-16"
            totEc={enhancedVehicle.enhanced.totEc}
            additionalRange={enhancedVehicle.enhanced.additionalRange}
            topUpRange={enhancedVehicle.enhanced.topUpRange}
            chargingDuration={enhancedVehicle.TopUpCharging?.ChargingDuration}
            chargingPowerKw={enhancedVehicle.TopUpCharging?.ChargingPowerKw}
            baseRange={enhancedVehicle.enhanced.baseRange}
            batteryLife={batteryLife}
            estimatedCycleEnergyPerKWhKm={cycledEnergyKWhKm}
            hasChargingEvents={enhancedVehicle.enhanced.hasChargingEvents}
            isSoH80={vehicleSettings.isSoH80}
            isWinter={vehicleSettings.isWinter}
            showTopUp={vehicleSettings.showTopUp}
            setIsSoH80={setIsSoH80Callback}
            setIsWinter={setIsWinterCallback}
            setShowTopUp={setShowTopUpCallback}
            dailyDistance={enhancedVehicle.ExecutedVehicleProduct.DailyOdometerMedianKm}
            longestDailyDistance={enhancedVehicle.ExecutedVehicleProduct.DailyOdometerLongestKm}
          />
          {getPtoDevicesQuery.data && (
            <div className="pt-spacing-12 px-spacing-16 pb-spacing-16">
              <VehicleInfo netEc={netEc} vehicle={enhancedVehicle} ptoDevices={getPtoDevicesQuery.data} />
            </div>
          )}
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Charging events' && (
        <ErrorBoundary>
          <CandidateCharging
            baseRange={enhancedVehicle.enhanced.baseRange}
            vehicle={enhancedVehicle}
            solutionId={solutionId}
            setDepotYear={setDepotYear}
            enableYearSelectorForChargingEvents={enableYearSelectorForChargingEvents}
          />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Historical stops' && (
        <ErrorBoundary>
          <HistoricalStops
            className={classes['historical-stops-wrapper']}
            solutionId={solutionId}
            enhancedVehicles={enhancedListVehicles}
            mapLocation={mapLocation}
          />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Environmental impact' && (
        <ErrorBoundary>
          <EnvironmentalImpactWrapper solutionId={solutionId} vehicle={vehicle} />
        </ErrorBoundary>
      )}
      {tabs[tabIndex].name === 'Financial evaluation' &&
        vehicle.TcoCalculations &&
        vehicle.TcoCalculations?.ShowInPresentationApp === true && (
          <ErrorBoundary>
            <FinancialEvaluation solutionId={solutionId} vehicle={enhancedVehicle} />
          </ErrorBoundary>
        )}
    </div>
  );
};

export default Main;
