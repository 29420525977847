import classNames from 'classnames';
import useTruckConfiguration, { TruckConfiguration } from './useTruckConfiguration';
import { FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

type TruckType = 'trailerTractor' | 'rigidTruck';

const TRUCK_TYPES: TruckType[] = ['trailerTractor', 'rigidTruck'];

const useTruckRows = ({
  articulatedTrucks,
  rigidTrucks,
  chassisType,
}: {
  articulatedTrucks: FactBasicTruck[];
  rigidTrucks: FactBasicTruck[];
  chassisType: string;
}) => {
  const articulatedTrucksConfiguration = useTruckConfiguration(articulatedTrucks);
  const rigidTrucksConfiguration = useTruckConfiguration(rigidTrucks);

  const groupedTruckConfiguration: {
    trailerTractor?: TruckConfiguration;
    rigidTruck?: TruckConfiguration;
  } = useMemo(
    () => ({
      trailerTractor: articulatedTrucks.length ? articulatedTrucksConfiguration : undefined,
      rigidTruck: rigidTrucks.length ? rigidTrucksConfiguration : undefined,
    }),
    [articulatedTrucks, articulatedTrucksConfiguration, rigidTrucks, rigidTrucksConfiguration],
  );

  return useMemo(
    () =>
      chassisType === 'Truck'
        ? [
            {
              title: 'Wheel configuration',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.wheelConfigurations.join(', '),
                }),
                {},
              ),
            },
            {
              title: 'Axle distance',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.axleDistanceMinMax,
                }),
                {},
              ),
            },
            {
              title: 'Cab options',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.cabs.join(', '),
                }),
                {},
              ),
            },
            {
              title: 'Engine gearbox',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.engineGearboxes.map((engineGearbox) => (
                    <div key={engineGearbox}>{engineGearbox}</div>
                  )),
                }),
                {},
              ),
            },
            {
              title: 'Battery system',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.batterySystems.map((batterySystem) => (
                    <div key={batterySystem}>{batterySystem} kWh installed energy</div>
                  )),
                }),
                {},
              ),
            },
            {
              title: 'Charging',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration?.[truckType]?.chargingTypesWithMaxPower.map(
                    (chargingTypeWithMaxPower, index) => (
                      <div key={index} className={classNames({ 'tds-u-mt1': index })}>
                        {chargingTypeWithMaxPower.ChargingType}, {chargingTypeWithMaxPower.ChargingPowerKW} kW
                      </div>
                    ),
                  ),
                }),
                {},
              ),
            },
            {
              title: 'Max. GTW',
              ...TRUCK_TYPES.reduce(
                (acc, truckType) => ({
                  ...acc,
                  [truckType]: groupedTruckConfiguration[truckType] ? (
                    <div>Max {groupedTruckConfiguration[truckType]?.maxGtws.join(', ')} t</div>
                  ) : null,
                }),
                {},
              ),
            },
          ]
        : [],
    [groupedTruckConfiguration, chassisType],
  );
};

export default useTruckRows;
