import { FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicTrucks?: FactBasicTruck[];
}

const useCabs = ({ basicTrucks }: Props) =>
  useMemo(
    () => (basicTrucks ? [...new Set(basicTrucks.map((basicTruck) => basicTruck.Cabs).flat())].sort() : []),
    [basicTrucks],
  );

export default useCabs;
