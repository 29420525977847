import useLoadAuthTokens from 'features/auth/utils/useLoadAuthTokens';
import useLoadingText from 'common/hooks/useLoadingText';
import useLoadUser from 'features/auth/utils/useLoadUser';
import { Loading } from '@optimization/ssi-common';
import { useEffect, useState } from 'react';

const LoadUser = ({ children }: { children: React.ReactNode }) => {
  const authTokensState = useLoadAuthTokens();
  const userState = useLoadUser();
  const isLoading = authTokensState.isLoading || userState.isLoading;
  const isError = userState.isError;
  const loadingText = useLoadingText({ isLoadingUser: true });
  const [loadingCount, setLoadingCount] = useState(0);

  useEffect(() => {
    if (isLoading) {
      setLoadingCount((prev) => prev + 1);
    }
  }, [isLoading]);

  return (
    <>
      {loadingCount < 2 && <Loading isLoading={isLoading} isError={isError} loadingText={loadingText} />}
      {children}
    </>
  );
};

export default LoadUser;
