import classes from './style.module.scss';
import classNames from 'classnames';
import React from 'react';
import TruckFrontRegional from 'assets/truck-front-regional.png';
import TruckFrontUrban from 'assets/truck-front-urban.png';
import { KeyedString, ScaniaLogo } from '@optimization/ssi-common';
import { SALES_READY_TEXT } from '@optimization/sa-common';

const truckImages: KeyedString = {
  [SALES_READY_TEXT]: TruckFrontUrban,
  '24.06': TruckFrontRegional,
  '24.11': TruckFrontRegional,
};

interface TruckHeroProps {
  selectedLaunchPeriod: string;
}

const TruckHero: React.FC<TruckHeroProps> = ({ selectedLaunchPeriod }) => {
  const truckImage = truckImages[selectedLaunchPeriod];

  return truckImage ? (
    <article className={classes['truck-hero']}>
      <div>
        <ScaniaLogo />
        <h4 className={classNames(classes.market, 'tds-headline-01')}>Quiet Power</h4>
      </div>
      <div>
        <img src={truckImage} alt={`${selectedLaunchPeriod} truck`} />
      </div>
    </article>
  ) : null;
};

export default TruckHero;
