import classes from './style.module.scss';
import classNames from 'classnames';
import useLoadingText from 'common/hooks/useLoadingText';
import { EnvironmentalImpact, LabelAndValue, VehicleTransformed } from '@optimization/sa-common';
import { Loading } from '@optimization/ssi-common';
import { useGetEnvironmentalImpactQuery, useUpdateVehicleMutation } from 'src/app/services/solution';

interface Props {
  solutionId: string;
  vehicle: VehicleTransformed;
}

const EnvironmentalImpactWrapper = ({ solutionId, vehicle }: Props) => {
  const environmentalImpactQuery = useGetEnvironmentalImpactQuery({ solutionId, vehicleId: vehicle.Id });
  const loadingText = useLoadingText({ isLoadingEnvironmentalImpact: environmentalImpactQuery.isLoading });

  const [, updateVehicleState] = useUpdateVehicleMutation();

  return (
    <div className={classes['env-impact']}>
      <Loading
        isLoading={environmentalImpactQuery.isLoading || updateVehicleState.isLoading}
        isError={environmentalImpactQuery.isError || updateVehicleState.isError}
        loadingText={loadingText}
      />
      {environmentalImpactQuery.data && (
        <div>
          <h5 className={classNames('tds-headline-05', classes.header)}>Comparable yearly savings</h5>
          <div className={classes['consumption-labels']}>
            <LabelAndValue
              label={'Consumption (' + environmentalImpactQuery.data.FuelTypeFriendlyString + ')'}
              value={`${environmentalImpactQuery.data.ConsumptionPer100Km.toString()} ${environmentalImpactQuery.data.FuelUnitShort}/100km`}
            />
            <LabelAndValue
              label="Country of operation (Energy mix)"
              value={environmentalImpactQuery.data.Country || ''}
            />
          </div>
          <div className="px-spacing-16 pb-spacing-16">
            <EnvironmentalImpact environmentalImpact={environmentalImpactQuery.data} isPresentation />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnvironmentalImpactWrapper;
