import classes from './style.module.scss';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const DrivingTomorrow = ({ className }: Props) => (
  <div className={classNames(className, classes['driving-tomorrow'])}>
    <h2 className="tds-headline-02">Driving tomorrow.</h2>
    <p className="tds-body-01">
      By investing in our latest electric trucks, you're not just adopting cutting-edge technology – you're reducing
      operational costs, minimising environmental impact, and future-proofing your fleet for the demands of tomorrow.
      These advancements are designed to optimise your business, helping you stay ahead in a rapidly evolving industry
      while making a meaningful contribution to sustainability.
    </p>
  </div>
);

export default DrivingTomorrow;
