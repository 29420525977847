import classNames from 'classnames';
import useBusConfiguration from './useBusConfiguration';
import { FactBasicBus } from '@optimization/sa-common';
import { useMemo } from 'react';

const useBusRows = ({ bussesInStatus, chassisType }: { bussesInStatus: FactBasicBus[]; chassisType: string }) => {
  const bussesConfiguration = useBusConfiguration(bussesInStatus);

  return useMemo(
    () =>
      chassisType === 'Bus'
        ? [
            {
              title: 'Wheel configuration',
              busConfiguration: bussesConfiguration.wheelConfigurations.join(', '),
            },
            {
              title: 'Engine gearbox',
              busConfiguration: bussesConfiguration.engineGearboxes.map((engineGearbox) => (
                <div key={engineGearbox}>{engineGearbox}</div>
              )),
            },
            {
              title: 'Battery system',
              busConfiguration: bussesConfiguration.batterySystems.map((batterySystem) => (
                <div key={batterySystem}>{batterySystem} kWh installed energy</div>
              )),
            },
            {
              title: 'Charging',
              busConfiguration: bussesConfiguration.chargingTypesWithMaxPower.map((chargingTypeWithMaxPower, index) => (
                <div key={index} className={classNames({ 'tds-u-mt1': index })}>
                  {chargingTypeWithMaxPower.ChargingType}, {chargingTypeWithMaxPower.ChargingPowerKW} kW
                </div>
              )),
            },
            {
              title: 'Body length',
              busConfiguration: bussesConfiguration.bodyLengths.join(', '),
            },
            {
              title: 'Floor height',
              busConfiguration: bussesConfiguration.floorHeights.join(', '),
            },
          ]
        : [],
    [bussesConfiguration, chassisType],
  );
};

export default useBusRows;
