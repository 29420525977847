import AppInfo from './AppInfo';
import classes from './style.module.scss';
import truckSide from 'assets/truck-side.png';
import useLogin from '../utils/useLogin';
import { Button } from '@optimization/ssi-common';

function Login() {
  const login = useLogin();

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <div className={classes.top}>
          <div>
            <h1 className="tds-headline-01">Welcome to your electric Scania fleet</h1>
          </div>
          <Button
            onClick={login}
            variant="primary"
            size="lg"
            text="Explore the shift"
            mode-variant="secondary"
            className="tds-u-mt3"
          />
        </div>
        <AppInfo className={classes.bottom} />
      </div>
      <div className={classes.right}>
        <img src={truckSide} alt="Truck from side" />
      </div>
    </div>
  );
}

export default Login;
