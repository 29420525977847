import { IColumn, RowItemBase } from '@optimization/ssi-common';
import { ReactNode } from 'react';

export interface BusRowItem extends RowItemBase {
  busConfiguration: ReactNode;
  title: string;
}

export const busColumns: IColumn<BusRowItem>[] = [
  {
    columnKey: 'specifications',
    columnTitle: 'Specifications',
    render: (row: BusRowItem) => row.title,
  },
  {
    columnKey: 'bus',
    columnTitle: 'Bus',
    render: (row: BusRowItem) => row.busConfiguration,
  },
];
