import Datadog from 'features/datadog';
import Layout from 'common/components/Layout';
import LoadUser from 'features/auth/LoadUser';
import PageNotFound from 'common/components/PageNotFound';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteError from 'common/components/RouteError';
import SideNavbarSolutionPresentation from 'features/navigation/SideNavbar/SolutionPresentation';
import SolutionManager from 'features/solution/SolutionManager';
import SolutionPresentationHome from 'features/presentation/Home';
import SolutionPresentationPerformanceSteps from 'features/presentation/PerformanceSteps';
import SolutionPresentationProductShowcase from 'features/presentation/ProductShowcase';
import SolutionPresentationStepDetail from 'features/presentation/PerformanceStepDetail';
import { APP_TITLE, APP_USP } from 'app/config';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { defineCustomElements } from '@scania/tegel-react';
import { ElementControllerProvider } from 'app/context/ElementControllerContext';
import { enableMocking } from './mocks/setup';
import { Helmet } from 'react-helmet';
import { msalConfig } from 'app/config/auth';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { StickyHandlerProvider } from 'app/context/StickyHandlerContext';
import { store } from 'app/store';
import { ToastProvider, UpdateApp } from '@optimization/ssi-common';
import './index.scss';

defineCustomElements();

export const msalInstance = new PublicClientApplication(msalConfig);

export const routes = {
  errorElement: <RouteError />,
  element: (
    <ElementControllerProvider>
      <Helmet>
        <title>
          {APP_TITLE} - {APP_USP}
        </title>
      </Helmet>
      <LoadUser>
        <Datadog>
          <UpdateApp appTitle={APP_TITLE}>
            <StickyHandlerProvider>
              <Layout>
                <Outlet />
              </Layout>
            </StickyHandlerProvider>
          </UpdateApp>
        </Datadog>
      </LoadUser>
    </ElementControllerProvider>
  ),
  children: [
    {
      path: '/',
      element: <SolutionManager />,
    },
    {
      element: (
        <SideNavbarSolutionPresentation>
          <Outlet />
        </SideNavbarSolutionPresentation>
      ),
      children: [
        {
          element: <SolutionPresentationHome />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/home',
        },
        {
          element: <SolutionPresentationProductShowcase />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/product-showcase',
        },
        {
          element: <SolutionPresentationPerformanceSteps />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps',
        },
        {
          element: <SolutionPresentationStepDetail />,
          errorElement: <RouteError />,
          path: '/solution/:solutionId/presentation/performance-steps/:yearParam',
        },
      ],
    },
    {
      path: 'callback',
    },
    {
      path: '/*',
      element: <PageNotFound className="tds-u-mt3 pt-spacing-64" />,
    },
  ],
};
const router = createBrowserRouter([routes]);

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ToastProvider>
            <RouterProvider router={router} />
          </ToastProvider>
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
