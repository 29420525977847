import { FactBasicBus, FactBasicTruck } from '@optimization/sa-common';
import { useMemo } from 'react';

interface Props {
  basicVehicles?: (FactBasicTruck | FactBasicBus)[];
}

const useTruckWheelConfigurations = ({ basicVehicles }: Props) =>
  useMemo(
    () =>
      basicVehicles
        ? [...new Set(basicVehicles.map((basicVehicle) => basicVehicle.WheelConfiguration).flat())].sort()
        : [],
    [basicVehicles],
  );

export default useTruckWheelConfigurations;
