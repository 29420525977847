import classes from './style.module.scss';
import { CountBox } from '@optimization/sa-common';

interface Props {
  chargersCount: number;
}

const Chargers = ({ chargersCount }: Props) => (
  <CountBox
    header="Chargers"
    subHeader="Required for transformation"
    count={chargersCount}
    countClassName={classes.count}
  />
);

export default Chargers;
