import Chargers from './Chargers';
import classes from './style.module.scss';
import Depots from './Depots';
import DistanceElectrified from './DistanceElectrified';
import DrivingTomorrow from './DrivingTomorrow';
import ElectrificationJourney from './ElectrificationJourney';
import React from 'react';
import UniqueSellingPoint from './UniqueSellingPoint';
import useChargersCount from './Chargers/useChargersCount';
import useDepotsCount from './Depots/useDepotsCount';
import useDistanceElectrified from './DistanceElectrified/useDistanceElectrified';
import { ListDepotTransformed, ListVehicleEnhanced } from '@optimization/sa-common';
import { SolutionPerformanceStep } from '../../PerformanceSteps/Steps/utils/types';

interface SolutionContentProps {
  filteredCandidates?: ListVehicleEnhanced[];
  depots?: ListDepotTransformed[];
  performanceSteps: SolutionPerformanceStep[];
}

const FleetSummary: React.FC<SolutionContentProps> = ({ filteredCandidates = [], depots = [], performanceSteps }) => {
  const distanceElectrified = useDistanceElectrified(filteredCandidates);
  const chargersCount = useChargersCount(depots);
  const depotsCount = useDepotsCount(depots);

  return (
    <div className={classes['fleet-summary']}>
      {filteredCandidates.length > 0 ? (
        <>
          <div className={classes.top}>
            <div className={classes['distance-depot-and-chargers']}>
              <DistanceElectrified distanceElectrified={distanceElectrified} />
              {depotsCount > 0 && (
                <div className={classes['depot-and-chargers']}>
                  <Depots depotsCount={depotsCount} />
                  <Chargers chargersCount={chargersCount} />
                </div>
              )}
            </div>
            <ElectrificationJourney filteredCandidates={filteredCandidates} performanceSteps={performanceSteps} />
          </div>
          <DrivingTomorrow />
          <div className={classes['unique-selling-points']}>
            <UniqueSellingPoint header="01">100% emission-free operations — including PTO</UniqueSellingPoint>
            <UniqueSellingPoint header="02">
              Regional capabilities with good flexibility covering medium range routes
            </UniqueSellingPoint>
            <UniqueSellingPoint header="03">Optimised total operating economy</UniqueSellingPoint>
            <UniqueSellingPoint header="04">New contract opportunities for zero-emission vehicles</UniqueSellingPoint>
          </div>
        </>
      ) : (
        <>Found no vehicles</>
      )}
    </div>
  );
};

export default FleetSummary;
